import { PlusCircleOutlined } from "@ant-design/icons";

const ItemsIncluded = ({ setItemsIncluded, itemsIncluded, setFormData }) => {
  const addItem = () => {
    if (itemsIncluded?.length < 5) {
      setItemsIncluded([...itemsIncluded, ""]);
    } else if (!itemsIncluded) {
      setItemsIncluded([""]);
    }
  };
  const handleInputChange = (index, value) => {
    const updatedInputs = itemsIncluded && [...itemsIncluded];
    updatedInputs[index] = value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      items_included: updatedInputs,
    }));
  };

  return (
    <div className="form-group">
      <label>Items incluídos</label>
      {itemsIncluded?.map((value, index) => (
        <input
          className="form-control mb-2"
          placeholder="Ítem"
          key={index}
          defaultValue={value}
          name="items_included"
          onChange={(e) => handleInputChange(index, e.target.value)}
        />
      ))}
      <button className="btn text-lg text-primary" onClick={addItem}>
        <PlusCircleOutlined />
      </button>
    </div>
  );
};

export default ItemsIncluded;
