import { useEffect, useState } from "react";
import { MenuOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import useIsMobile from "../hooks/responsive/use-is-mobile";
import { Link, useLocation } from "react-router-dom";
import instagram from "../assets/images/icons/instagram.svg";
import logo from "../assets/images/hero/horizontal-logo-transparent.svg";

const Navbar = () => {
  const location = useLocation();
  const data = location.state;
  const isMobile = useIsMobile();

  const handleClick = (e, id) => {
    e.preventDefault(); // Evitar el comportamiento predeterminado del enlace
    const sectionElement = document.getElementById(id); // Obtener el elemento con el id correspondiente
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" }); // Realizar el desplazamiento suave
    }
  };

  const navigateContact = () =>{
    window.open("https://wa.me/+34673553301?text=Tengo%20una%20consulta", "_blank")

  }

  const items = [
    {
      label: (
        <a  
        onClick={(e) => handleClick(e,'talleres')}
        className="cursor-pointer nav-link text-dark font-weight-bold d-flex align-items-center me-2">
          Talleres
        </a>
      ),
      key: "talleres",
    },
    {
      label: (
        <a
          onClick={() => window.location.replace("/reserva")}
          className="cursor-pointer nav-link text-dark font-weight-bold d-flex align-items-center me-2"
        >
          Reservas
        </a>
      ),
      key: "reserva",
    },
    {
      label: (
        <a
        onClick={() => window.location.replace("/team-building")} 
        className="nav-link text-dark font-weight-bold d-flex align-items-center me-2">
          Empresas
        </a>
      ),
      key: "teamBuilding",
    },
    {
      label: (
        <a 
        onClick={(e) => handleClick(e,'sobreNosotros')}
         className="nav-link text-dark font-weight-bold d-flex align-items-center me-2">
          Quienes somos
        </a>
      ),
      key: "sobreNosotros",
    },
    {
      label: (
        <div
          className="nav-link text-dark font-weight-bold d-flex align-items-center me-2"
          onClick={navigateContact}
        >
          Contacto
        </div>
      ),
      key: "contacto",
    },
  ];

  useEffect(() => {
    if (data) {
      const sectionElement = document.getElementById(data);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [data]);

  return (
    <nav className="navbar navbar-expand-lg blur border-radius-sm top-0 z-index-3 shadow position-sticky py-3 start-0 end-0">
      <div className="container px-1">
        <a className="font-weight-bolder ms-lg-0 " href="/">
          <img className="logo" alt="muerdete-logo" src={logo} />
        </a>
        {isMobile && (
          <Dropdown
            menu={{
              items,
            }}
            trigger={["click"]}
          >
            <MenuOutlined />
          </Dropdown>
        )}
        <div className="collapse navbar-collapse" id="navigation">
          <ul className="navbar-nav ms-auto">
            {items?.map((item) => (
              <Link state={item.key} key={item.key} to={"/"} className="nav-item">
                {item.label}
              </Link>
            ))}
            <li className="nav-item">
              <a
                className="nav-link text-dark font-weight-bold d-flex align-items-center me-2 cursor-pointer"
                onClick={() =>
                  window.open("http://www.instagram.com/muerdete", "_blank")
                }
              >
                <img className="social-media-icon" src={instagram} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
