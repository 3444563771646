
export default function BookingStatus({ slider }) {

  let widthClass = "";

  switch (slider) {
    case 0:
      widthClass = " w-5"
      break;
    case 1:
      widthClass = " w-55"
      break;
    case 2:
      widthClass = " w-90"
      break;
  }

  return (
    <>
      <div className="mx-auto mb-4 col-12 w-450-px">
        <div className="mt-4">
          <div className="progress my-3">
            <div className={`progress-bar bg-primary` + widthClass} role="progressbar"></div>
          </div>
          <div className="d-flex justify-content-between">
            <p className="cursor-pointer font-weight-semibold text-primary">Datos del bono</p>
            {slider === 0 &&
              <>
                <p className="font-weight-semibold">Seleccionar fecha</p>
                <p className="font-weight-semibold">Tus datos</p>
              </>
            }
            {slider === 1 &&
              <>
                <p className="font-weight-semibold text-primary">Seleccionar fecha</p>
                <p className="font-weight-semibold">Tus datos</p>
              </>
            }
            {slider === 2 &&
              <>
                <p className="font-weight-semibold text-primary">Seleccionar fecha</p>
                <p className="font-weight-semibold">Tus datos</p>
              </>
            }
          </div>
        </div>
      </div>
    </>
  );
}