import React, { useState, useEffect, useMemo } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import { Button, Modal, Spin } from 'antd';
import dayjs from 'dayjs';

import useGetLessons from "../../../hooks/data/use-get-lessons";
import { useDate } from '../../../context/Provider';

const LessonsCalendar = ({ setActiveTabKey, setSelectedLessonDate }) => {
    const lessons = useGetLessons();
    const allLessons = useMemo(() => lessons || [], [lessons]);
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [loading, setLoading] = useState(true);
    const { selectedDate, setSelectedDate } = useDate();


    useEffect(() => {
        if (Array.isArray(allLessons) && allLessons.length > 0) {
            const events = allLessons.map((lesson) => {
                const startDate = new Date(lesson.lessonDate);
                const endDate = new Date(lesson.lessonDate);

                const startTime = lesson.startHour.split(':');
                const endTime = lesson.finishHour.split(':');
                startDate.setHours(startTime[0], startTime[1]);
                endDate.setHours(endTime[0], endTime[1]);

                return {
                    id: lesson.id,
                    title: `${lesson.startHour.slice(0, 5)} - ${lesson.course?.title}`,
                    start: startDate.toISOString(),
                    end: endDate.toISOString(),
                    extendedProps: { ...lesson },
                };
            });
            setCalendarEvents(events);
            setLoading(false);
        }
    }, [allLessons]);

    const handleEventClick = ({ event }) => {
        setSelectedEvent(event.extendedProps);
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
        setSelectedEvent(null);
    };

    const renderEventContent = (eventInfo) => {
        const color = eventInfo.event.extendedProps.students.length > 0 ? "green" : "red";
        return (
            <p className='text-sm' style={{ color }}>{eventInfo.event.title}</p>
        );
    };

    const handleClickEditLesson = () => {
        const formattedLessonDate = dayjs(selectedEvent.lessonDate).format('YYYY-MM-DD');
        setSelectedDate(formattedLessonDate);
        setActiveTabKey('2');
        localStorage.setItem('activeTabKey', '2')
        closeModal();
    };

    if (loading) {
        return <Spin size="large" />;
    }

    return (
        <div>
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                }}
                events={calendarEvents}
                eventClick={handleEventClick}
                eventContent={renderEventContent}
                locale={esLocale}
            />
            {selectedEvent && (
                <Modal
                    open={isModalVisible}
                    onOk={closeModal}
                    onCancel={closeModal}
                >
                    <p className='text-3xl'><strong>{selectedEvent.course.title}</strong></p>
                    <p><strong>Horario:</strong> {selectedEvent.startHour.slice(0, 5)} a {selectedEvent.finishHour.slice(0, 5)} hs</p>
                    <p><strong>Personas disponibles: </strong>{selectedEvent.seats}</p>
                    <Button onClick={handleClickEditLesson} className='w-100' type='primary'>Ir a taller</Button>
                </Modal>
            )}
        </div>
    );
};

export default LessonsCalendar;
