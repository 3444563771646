import { Timeline } from 'antd';
import backgroundImage from "../../assets/images/hero/shapes_background_muerdete.svg";
import useIsMobile from '../../hooks/responsive/use-is-mobile';

export const ProductTimeline = ({
    timeline,
}) => {
    const isMobile = useIsMobile()
    const styles = {
        timeline: {
            backgroundSize: 'cover',
            backgroundImage: !isMobile && 'url(' + backgroundImage + ')'
        },
    };
    const items = timeline?.map((item) => ({
        children: <div className="card shadow-xs border bg-gray-100">
            <div className="card-body p-lg-3">
                <h6 className="mb-2">{item?.title}</h6>
                {item?.description}</div></div>,
        ...item,
    }));


    return (
        <div style={styles.timeline}>
            <h2 className="p-5">Modalidad del taller</h2>
            <div className='w-60 mx-auto timeline'>
                <Timeline
                    mode={!isMobile && 'alternate'}
                    items={items}
                />
            </div>
        </div>)
}
export default ProductTimeline