import { Link } from 'react-router-dom';

const GroupProduct = ({
  pageHeaderBgImg,
  pageHeaderMinVh
}) => {
  const styles = {
    pageHeader: {
      minHeight: "100vh",
      backgroundImage: 'url(' + pageHeaderBgImg + ')',
      minHeight: pageHeaderMinVh,
    },
  };

  return (
    <div className="page-header py-8" style={styles.pageHeader}>
      <div className="faded-blue-horizontal opacity-10"></div>
      <div className="container z-index-2">
        <div className="row justify-content-between">
          <div className="col-sm-6 mx-auto">
            <div className='d-flex flex-column justify-content-start '>
              <h1 className="text-white">Talleres Privados</h1>
              <p className="text-white mb-2 text-middle">(a partir de 10 personas)</p>
            </div>
            <p className="lead text-white text-2xl">En nuestro lugar, cada motivo es una razón para festejar. Ya sea tu cumpleaños, despedida de soltero o incluso tu divorcio, aprenderás a cocinar platos deliciosos de manera sencilla y divertida. ¡Te garantizamos una experiencia única!</p>
            <Link
              className="btn btn-white btn-lg btn-lg my-4 text-xl" to="/taller-grupal"
            >
              Más información
            </Link>
          </div>
          <div className="col-sm-6" />
        </div>
      </div>
    </div>
  );
};
export default GroupProduct;
