import bookingBackground from "../../assets/images/hero/yellow_background.png";
import useIsMobile from "../../hooks/responsive/use-is-mobile";

const BookingHero = () => {
  const isMobile = useIsMobile();
  const styles = {
    bookingBackground: {
      backgroundImage: "url(" + bookingBackground + ")",
      minHeight: "90vh",
    },
    buttonsRow: {
      display: isMobile ? "block" : "flex",
    },
    button: {
      width: isMobile && "100%",
    },
  };

  return (
    <div class="page-header py-5 py-md-0" style={styles.bookingBackground}>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8 col-sm-9 text-center mx-auto">
            <h1 class="mb-4">Reserva una plaza</h1>
            <p class="lead mb-sm-6 mb-4">
              Reserva una plaza para realizar uno de nuestros talleres. Puedes
              utilizar un bono que hayas recibido de regalo, o gestionar una
              nueva compra.
            </p>
            <div
              style={styles.buttonsRow}
              className="gap-4 justify-content-center"
            >
              <a
                style={styles.button}
                href="#booking-form"
                class="btn btn-white btn-lg"
              >
                Ya tengo un bono
              </a>
              <a href={"/"} style={styles.button} class="btn btn-white btn-lg">
                Ver talleres
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BookingHero;
