import { useEffect } from "react";
import { useParams } from "react-router-dom";

import Navbar from "../components/navbar";
import ShoppingCart from "../components/cart/shoppingCart";
import "../assets/scss/astro-ecommerce.scss";
import { useLesson } from "../context/Provider";

export const ShoppingCartComponent = ({ setCourseId, course }) => {
  const { courseId } = useParams();
  const { selectedLesson, setSelectedLesson } = useLesson();


  useEffect(() => {
    setCourseId(courseId);
  }, []);

  return (
    <>
      <Navbar />
      {course && <ShoppingCart
        course={course}
        selectedLesson={selectedLesson}
        setSelectedLesson={setSelectedLesson}
      />
      }
    </>
  );
};

export default ShoppingCartComponent;
