import useIsMobile from "../../hooks/responsive/use-is-mobile";
import backgroundImage from "../../assets/images/hero/portadaHome.jpg";

export const TestimonialsFade = ({
  title,
  talleresRef,
  fullDescription,
  firstButtonTitle,
  secondButtonTitle,
}) => {
  const isMobile = useIsMobile();

  const styles = {
    pageHeader: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundPosition: isMobile ? "center right" : "center",
      backgroundSize: "cover",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      position: "relative",
    },
    bonusCard: {
      zIndex: '9999',
      position: "absolute",
      top: isMobile ? "5%" : "5%", // Altura ajustada
      left: "50%", // Centro horizontal
      transform: "translateX(-50%)", // Ajusta para centrar completamente
      textAlign: "center",
    },
    bonusText: {
      fontSize: isMobile ? "1.5em" : "2em", // Tamaño ajustado
      fontWeight: "bold",
      color: "#000", // Título en negro
      marginBottom: "0.5em", // Espacio entre texto y precio
    },
    buttons: {
      width: "90%", // Asegura que ambos botones tengan el mismo ancho
      // Ancho máximo en pantallas grandes
      textAlign: "center",
      fontSize: "1.25rem",
      borderRadius: "8px",
    },
    price: {
      fontSize: isMobile ? "2.5em" : "3.5em", // Tamaño más grande
      fontWeight: "bold",
      color: "#FF0000", // Precio en rojo
    },
  };


  const scrollToTalleres = () => {
    if (talleresRef.current) {
      talleresRef.current.scrollIntoView({ behavior: "smooth" }); // Desplazamiento suave a la sección
    }
  };

  return (
    <section className="gb-pink" style={styles.pageHeader}>
      {/* Cartel BONO */}
      <div
        style={styles.bonusCard}
      >
        <p style={styles.bonusText}>BONO PARA 2 PERSONAS</p>
        <p style={styles.price}>65 €</p>
      </div>

      <div className="page-header py-2 py-md-5 flex h-100 align-items-center">
        {isMobile && <span className="mask bg-gradient opacity-3"></span>}
        <div className="container">
          <div className="row justify-content-center">
            <div
              className="col-md-6 m-auto flex-column d-flex"
              style={styles.headerInfo}
            >
              <h1 className="mb-4">{title}</h1>
              <p
                className={`${isMobile ? "text-lg" : "text-2xl"}`}
                style={{ textAlign: "left", color: "#000" }}
              >
                {fullDescription}
              </p>
              <div style={styles.buttons}>
              <a
                className="btn btn-lg bg-purple text-white text-xl"
                onClick={scrollToTalleres}
              >
                {secondButtonTitle}
              </a>
              <a
                className={`btn btn-lg ${isMobile ? "bg-white text-black" : "bg-yellow text-white"
                  } text-xl`}
                href="/reserva"
              >
                {firstButtonTitle}
              </a>
            </div>
            </div>
            <div
              style={{ display: isMobile ? "none" : "block" }}
              className="col-sm-6 text-center"
            >
              {/* Imagen opcional */}
            </div>
          </div>
        </div>
      </div>

      {/* Sección Talleres */}

    </section>
  );
};

export default TestimonialsFade;
