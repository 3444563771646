import Navbar from "../components/navbar";
import GroupCourseHero from "../components/groupCourse/hero";
import image1 from "../assets/images/hero/team_building_4.jpg";
import image2 from "../assets/images/hero/team_building_11.jpg";
import image3 from "../assets/images/hero/team_building_2.jpg";
import image4 from "../assets/images/hero/team_building_6.jpg";

export const TeamBuilding = () => {
  return (
    <>
      <Navbar />
      <GroupCourseHero  
        title={"Team building"}
        description={"¡Bienvenidos a Muerdete, donde convertimos la cocina en una aventura épica para empresas, grupos y equipos! En Muerdete, creemos que la cocina es una forma de conectars, colaborar y celebrar."}
        />
<div className="container mt-5 mb-5">
    <h3>Nuestros talleres de cocina para empresas</h3>
    <p>Son mucho más que una actividad de team building. Son una experiencia culinaria única que une a tu equipo de una manera divertida y memorable. Aquí tienes lo que podemos ofrecerte:</p>

    <ol style={{ paddingInlineStart: '20px' }}>
      <li style={{ marginBottom: '10px' }}><strong>Creatividad y Colaboración:</strong> Nuestros talleres fomentan la creatividad y la colaboración. Trabajar juntos en la cocina para crear platos deliciosos es una forma divertida de fortalecer los lazos de tu equipo.</li>
      <li style={{ marginBottom: '10px' }}><strong>Comunicación Efectiva:</strong> La cocina requiere comunicación constante y precisa. Nuestros talleres te ayudarán a mejorar la comunicación dentro de tu equipo mientras cocinan juntos.</li>
      <li style={{ marginBottom: '10px' }}><strong>Desafío y Diversión:</strong> ¿Estás listo para un desafío culinario? Nuestros chefs te guiarán a través de recetas emocionantes y te retarán a superarte a ti mismo. ¡Pero siempre con una sonrisa en el rostro!</li>
      <li style={{ marginBottom: '10px' }}><strong>Flexibilidad Personalizada:</strong> Adaptamos nuestros talleres para satisfacer las necesidades de tu grupo, ya sea un evento corporativo, una reunión de equipo o simplemente una salida divertida. Podemos organizar eventos personalizados para tu empresa.</li>
      <li style={{ marginBottom: '10px' }}><strong>Memorias Duraderas:</strong> La comida que preparas en nuestros talleres no es solo comida, es una experiencia compartida. Tus empleados o compañeros de equipo se llevarán recuerdos deliciosos que perdurarán mucho después de que termine el taller.</li>
    </ol>
</div>


      <div className="card p-lg-6">
        <div className="row mb-6">
          <div className="col-12 col-lg-5">
            <img class="w-90 rounded-2" src={image1} alt={image1} />
          </div>
          <div className="col-12 col-lg-7">
            <img class="w-90 rounded-2" src={image2} alt={image2} />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-7">
            <img class="w-90 rounded-2" src={image3} alt={image3} />
          </div>
          <div className="col-12 col-lg-5">
            <img class="w-90 rounded-2" src={image4} alt={image4} />
          </div>
        </div>
      </div>
    </>
  );
};
export default TeamBuilding;
