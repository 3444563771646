import { useState, useEffect } from "react";
import Navbar from "../components/navbar";
import Contact from "../components/store/contact"; // Not used in this example
import BackOfficeTabs from "../components/backoffice/tabs";
import Login from "../components/backoffice/login/form";

const BackOffice = () => {
  const [isLogged, setIsLogged] = useState(false);

  useEffect(() => {
    // Revisar si el usuario ya está autenticado al cargar el componente
    const loggedIn = localStorage.getItem("isLogged") === "true";
    setIsLogged(loggedIn);
  }, []);

  const handleLogin = (isLogged) => {
    // Guardar el estado de autenticación en localStorage
    localStorage.setItem("isLogged", isLogged);
    setIsLogged(isLogged);
  };

  return (
    <>
      <Navbar />
      {isLogged ? (
        <BackOfficeTabs />
      ) : (
        <Login setIsLogged={handleLogin} />
      )}
    </>
  );
};

export default BackOffice;
