import React, { useState, useEffect, useMemo } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DataProvider from "./context/Provider";
import ScrollToTop from "./hooks/tools/scrollToTop";
import Landing from "./pages/landing";
import Product from "./pages/product";
import Booking from "./pages/booking";
import GroupCourse from "./pages/groupCourse.js";
import ShoppingCartComponent from "./pages/shopping_cart";
import useGetCourses from "./hooks/data/use-get-courses";
import WhatsAppButton from "./components/whatsapp/whatsappButton";
import BackOffice from "./pages/backOffice";
import EditCourseForm from "./components/backoffice/courses/edit/form";
import CookieConsentComponent from "./components/cookieConsent/cookieConsent";
import AddLesson from "./components/backoffice/lessons/add/form";
import PurchaseSuccess from "./components/cart/purchaseSuccess";
import PurchaseError from "./components/cart/purchaseError";
import BookingSuccess from "./components/booking/bookingSuccess";
import BookingError from "./components/booking/bookingError";
import Contact from "./components/store/contact";
import AddBooking from "./components/backoffice/booking/add";
import TeamBuilding from "./pages/teamBuilding";
import NuestroEspacio from "./pages/nuestroEspacio.js";
import EditStudentForm from "./components/backoffice/courses/edit/editStudentForm.js";
import AddStudentToALesson from "./components/backoffice/lessons/addStudentToALesson.js";
import PoliticasPrivacidad from "./pages/politicasPrivacidad.js";
import EditLesson from "./components/backoffice/lessons/editLesson.js";
import CondicionesGenerales from "./pages/condicionesGenerales.js";
import { NextUIProvider } from "@nextui-org/react";

const Context = React.createContext({
  name: "Default",
});
function App() {
  const contextValue = useMemo(
    () => ({
      name: "Muerdete",
    }),
    []
  );
  const [course, setCourse] = useState({});
  const [courseId, setCourseId] = useState();
  const courses = useGetCourses();

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://consent.cookiebot.com/uc.js';
    script.id = 'Cookiebot';
    script.setAttribute('data-cbid', 'a3fd0485-4803-4b69-a64c-bd171c8f8494');
    script.setAttribute('data-blockingmode', 'auto');
    script.type = 'text/javascript';

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (!!courseId) {
      setCourse(
        courses?.filter(
          (course) =>
            course?.title?.toLowerCase() === courseId?.split("-").join(" ")
        )[0]
      );
    }
  }, [courses, courseId]);

  return (
    <div className="position-relative">
      <DataProvider>
        <Context.Provider value={contextValue}>
          <BrowserRouter>
            <ScrollToTop />
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <>
                    <Landing />
                    <CookieConsentComponent />
                    <WhatsAppButton
                      phoneNumber="+34673553301"
                      message="¡Hola! Estoy interesado en tus servicios."
                    />
                  </>
                }
              />
              <Route
                path="/:courseId"
                element={
                  <>
                    <Product
                      course={course}
                      setCourseId={(courseId) => setCourseId(courseId)}
                    />
                    <WhatsAppButton
                      phoneNumber="+34673553301"
                      message="¡Hola! Estoy interesado en tus servicios."
                    />
                  </>
                }
              />
              <Route
                path={"/:courseId/compra"}
                element={
                  <>
                    <ShoppingCartComponent
                      course={course}
                      setCourseId={(courseId) => setCourseId(courseId)}
                    />
                    <WhatsAppButton
                      phoneNumber="+34673553301"
                      message="¡Hola! Estoy interesado en tus servicios."
                    />
                  </>
                }
              />
              <Route
                path={"/reserva"}
                element={
                  <>
                    <Booking></Booking>
                    <WhatsAppButton
                      phoneNumber="+34673553301"
                      message="¡Hola! Estoy interesado en tus servicios."
                    />
                  </>
                }
              />
              <Route path={"/taller-grupal"} element={<GroupCourse />} />
              <Route exact path="/" element={<Landing />} />
              <Route
                path="/:courseId"
                element={
                  <Product
                    course={course}
                    setCourseId={(courseId) => setCourseId(courseId)}
                  />
                }
              />
              <Route
                path={"/:courseId/compra"}
                element={
                  <ShoppingCartComponent
                    course={course}
                    setCourseId={(courseId) => setCourseId(courseId)}
                  />
                }
              />
              <Route path={"/reserva"} element={<Booking />} />
              <Route path={"/taller-grupal"} element={<GroupCourse />} />
              <Route path={"/team-building"} element={<TeamBuilding />} />
              <Route path={"/nuestro-espacio"} element={<NuestroEspacio />} />
              <Route path={"/politicas-privacidad"} element={<PoliticasPrivacidad />} />
              <Route path={"/condiciones-generales"} element={<CondicionesGenerales />} />
              <Route path={"/backoffice"} element={<BackOffice />} />
              <Route path={"/backoffice/agregarAlumno/:lessonId"} element={<AddStudentToALesson />} />
              <Route
                path={"/backoffice/editar/:courseId"}
                element={<EditCourseForm isEdit={true} />}
              />
                  <Route
                path={"/backoffice/editar/leccion/:lessonId"}
                element={<EditLesson />}
              />
               <Route
                path={"/backoffice/editStudent/:studentId"}
                element={<EditStudentForm/>}
              />
              <Route
                path={"/backoffice/crear-curso"}
                element={<EditCourseForm isEdit={false} />}
              />
              <Route
                path={"/backoffice/agregar-taller"}
                element={<AddLesson />}
              />
              <Route path={"/compra/exito"} element={<PurchaseSuccess />} />
              <Route path={"/compra/error"} element={<PurchaseError />} />
              <Route path={"/reserva/exito"} element={<BookingSuccess />} />
              <Route path={"/reserva/error"} element={<BookingError />} />
              <Route
              path={"/backoffice/agregar-reserva"}
              element={<AddBooking />}
            />
            </Routes>
            <Contact id="contacto" />
          </BrowserRouter>
        </Context.Provider>
      </DataProvider>
    </div>
  );
}

export default App;
