export const optionsForSelect = [
    { value: 'vegan', label: 'Vegano' },
    { value: 'vegetarian', label: 'Vegetariano' },
    { value: 'glutenFree', label: 'Sin gluten' },
    { value: 'noShellfish', label: 'Sin mariscos' },
    { value: 'lactoseIntolerant', label: 'Intolerancia a la lactosa' },
    { value: 'noNuts', label: 'Sin frutos secos' },
    { value: 'diabetes', label: 'Diabetes' },
    { value: 'hypertension', label: 'Hipertensión' },
    { value: 'heartDisease', label: 'Enfermedad cardíaca' },
  ];


  export const specialRequestLiteralObject = {
    vegan: 'Vegano',
    vegetarian: 'Vegetariano',
    glutenFree: 'Sin gluten',
    noShellfish: 'Sin mariscos',
    lactoseIntolerant: 'Intolerancia a la lactosa',
    noNuts: 'Sin frutos secos',
    diabetes: 'Diabetes',
    hypertension: 'Hipertensión',
    heartDisease: 'Enfermedad cardíaca',
  };

  export const optionsForSelectMexican = [
    { value: 'lactoseIntolerant', label: 'Intolerancia a la lactosa' },
    { value: 'noShellfish', label: 'Sin mariscos' },
    { value: 'noNuts', label: 'Sin frutos secos' },
    { value: 'hypertension', label: 'Hipertensión' },
    { value: 'diabetes', label: 'Diabetes' },
  ];
  

  export const optionsForPasta = [
    { value: 'lactoseIntolerant', label: 'Intolerancia a la lactosa' },
    { value: 'noNuts', label: 'Sin frutos secos' },
    { value: 'vegetarian', label: 'Vegetariano' },
    { value: 'noShellfish', label: 'Sin mariscos' },
    { value: 'hypertension', label: 'Hipertensión' },
    { value: 'diabetes', label: 'Diabetes' },
  ];


  export const S3_BUCKET_NAME = 'https://muerdete-resourcesv2.s3.eu-west-3.amazonaws.com/courses'


