import { Link } from 'react-router-dom';


export default function PackageProduct({
    thumb_src,
    thumb_alt,
    title,
    price,
    position,
    minimumSpots,
}) {
    const classList = "card-body " + "text-" + position;

    return (
        <div className="col-md-6 col-lg-4 mb-4 mb-md-0 px-4">
            <div className="card card-product border-light mb-5 shadow-xl hover-effect">
                <Link to='https://api.whatsapp.com/send/?phone=%2B34673553301&text=Quiero+saber+mas+sobre+el+taller+grupal'  target="_blank">
                    <div className={classList}>
                        {title && (
                            <h2 className="font-weight-bold">{minimumSpots}</h2>
                        )}
                        <h4 className="font-weight-bold text-xl">PERSONAS</h4>
                        <img className="w-20 h-20 justify-content-center" src={thumb_src} alt={thumb_alt} />
                        <div className="px-4 my-3">
                            <p className="font-weight-bold mb-0">Para cualquier taller</p>
                            <p className="font-weight-bold mb-0">a escoger</p>
                        </div>
                        <h2 className="font-weight-bold">{price} € </h2>
                    </div>
                </Link>
            </div>
        </div>
    );
};
