import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Input, Form, notification, Switch, Button } from "antd";

import Highlights from "./highlights";
import ItemsIncluded from "./itemsIncluded";
import ImagesUpload from "./images";
import Navbar from "../../../navbar";
import useGetCourses from "../../../../hooks/data/use-get-courses";
import { LeftOutlined } from "@ant-design/icons";
import { convertFileToBase64 } from "../../../../utils/utilFunctions";
import SimpleImage from "./simpleImage";

const { TextArea } = Input;

const EditCourse = () => {
  const [api, contextHolder] = notification.useNotification();
  const courses = useGetCourses();
  const { courseId } = useParams();
  const course = courses?.find((course) => course.id === courseId);
  const [formData, setFormData] = useState(course || {});
  const [highlights, setHighlights] = useState();
  const [itemsIncluded, setItemsIncluded] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();
  const [isPrivate, setIsPrivate] = useState(course?.isPrivate || false);
  const [files, setFiles] = useState([]);
  const [heroImage, setHeroImage] = useState()


  useEffect(() => {
    setFormData(course);
    setHighlights(course?.highlights);
    setItemsIncluded(course?.items_included);
    setIsPrivate(course?.isPrivate)
    setFiles(course?.images)
    setHeroImage(course?.heroImage)

  }, [course]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: (name === "price" || name === "price2") ? parseFloat(value) : value,
    }));


  };


  const handleEdit = async (e) => {

    setIsSubmitted(true);
    if (!course) {
      let filesArray = []
      if (files) {
        filesArray = await Promise.all(files?.map(async uploadedFile => {
          const imgToSave = {
            file: await convertFileToBase64(uploadedFile),
            filename: uploadedFile.name,
          };
          return imgToSave;
        }));
      }
      formData.images = filesArray && filesArray;
      formData.isPrivate = isPrivate ? true : false;
      formData.heroImage = {
        file: await convertFileToBase64(heroImage),
        filename: heroImage.name
      }

      axios.post(process.env.REACT_APP_URI + `/v1/courses`, formData).then(
        api.success({
          message: `Taller creado`,
          description: "El taller ha sido creado con éxito.",
          placement: "bottomRight",
        }),
        setTimeout(() => navigate("/backoffice"), 1700)
      );
    } else if (!!course) {

      if (heroImage instanceof File){
        formData.heroToReplace = {
          file: await convertFileToBase64(heroImage),
          filename: heroImage.name
        }
      }

      const filesToAdd = files.filter(file => file instanceof File);

      const newFilesToSave = files.filter(file => !(file instanceof File));

      const filesArray = await Promise.all(filesToAdd.map(async uploadedFile => {
        const imgToSave = {
          file: await convertFileToBase64(uploadedFile),
          filename: uploadedFile.name,
        };
        return imgToSave;
      }));



      
      formData.filesToAdd = filesArray;
      formData.images = newFilesToSave
      axios
        .patch(process.env.REACT_APP_URI + `/v1/courses/${courseId}`, formData)
        .then(
          api.success({
            message: `Taller editado`,
            description: "El taller ha sido editado con éxito.",
            placement: "bottomRight",
          }),
          setTimeout(() => navigate("/backoffice"), 1700)
        );
    }
  }

  const handleChangePrivate = (e) => {
    setIsPrivate(e);
    setFormData((prevFormData) => ({
      ...prevFormData,
      isPrivate: e
    }));
  }


  const disabledReason = () => {
    return (
      !formData?.title
      || !formData?.price
      || !formData?.price2
      || !formData?.description
      || !formData?.confirmationText
      || !formData.stripePrice1
      || !formData.stripePrice2
      || !heroImage
      || !files
    )
  }


  const handleFileChange = (fileUploaded) => {
    const uploadedFiles = Array.from(fileUploaded);
    setFiles(prevFiles => [...(prevFiles || []), ...uploadedFiles]);
  }

  const handleFileDelete = (filesFiltered) => {
    const filteredImages = Array.from(filesFiltered);
    setFiles(filesFiltered)
  }

  const handleKeyPress = e => {
    const charCode = e.which || e.keyCode;

    if (charCode === 46 || (charCode === 44 && e.target.value.includes(","))) {
      e.preventDefault();
    } else if (charCode !== 44 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
    }
  };

  const handleHeroImage = (heroImageToSave) => {
    if (heroImageToSave) {
      setHeroImage(heroImageToSave[0])
    } else {
      setHeroImage(null)
    }
  }


  return (
    <>
      {contextHolder}
      <Navbar />

      <Form onSubmit={handleEdit}>
        <div>
          <div className="col-12 col-lg-8 p-3 p-md-5">
            <div className="cursor-pointer d-flex align-items-center gap-2 mb-4">
              <LeftOutlined />
              <a onClick={() => window.history.back()}>Volver</a>
            </div>
            <h4 className="mb-4">{course?.title}</h4>
            <div className="card border">
              <div className='d-flex justify-content-between align-items-center border-bottom'>
                <h6 className="p-lg-3">Datos del taller</h6>
                <div className='px-2'>
                  <div className='px-2 d-flex gap-2'>
                    ¿ Es privado ?
                    <Switch
                      checked={isPrivate}
                      onChange={(e) => handleChangePrivate(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="grid-template-columns-2">
                  <div className="form-group">
                    <label>Nombre</label>
                    <Input
                      className="form-control"
                      name="title"
                      value={formData?.title || ""}
                      onChange={handleChange}
                      placeholder="Enter the title"
                    />
                  </div>
                </div>
                <div className="grid-template-columns-2">
                  <div className="form-group">
                    <label>Precio Unitario</label>
                    <Input
                      type='number'
                      name="price"
                      value={formData?.price || ""}
                      onChange={handleChange}
                      onKeyPress={handleKeyPress}
                      placeholder="$35,5"
                    />
                  </div>
                  <div className="form-group">
                    <label>Precio Unitario Promocional</label>
                    <Input
                      type='number'
                      onKeyPress={handleKeyPress}
                      className="form-control"
                      name="price2"
                      value={formData?.price2 || ""}
                      onChange={handleChange}
                      placeholder="$32,5"
                    />
                  </div>
                </div>
                <div className="grid-template-columns-2">
                  <div className="form-group">
                    <label>Precio Stripe ( Unitario )</label>
                    <Input
                      className="form-control"
                      name="stripePrice1"
                      value={formData?.stripePrice1 || ""}
                      onChange={handleChange}
                      placeholder="Ej : price_1OeyTKDKPcvqIvHkOOMJVhii"
                    />
                  </div>
                  <div className="form-group">
                    <label>Precio Stripe (Promocional 2 o más unidades)</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="stripePrice2"
                      value={formData?.stripePrice2 || ""}
                      onChange={handleChange}
                      placeholder="Ej : price_1OeyRdDKPcvqIvHkDSIFm6MB "
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Descripción</label>
                  <TextArea
                    className="form-control"
                    placeholder="Descripción"
                    rows={5}
                    name="description"
                    value={formData?.description || ""}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label>Texto de confirmación</label>
                  <TextArea

                    className="form-control"
                    placeholder="Texto de confirmación"
                    rows={5}
                    name="confirmationText"
                    value={formData?.confirmationText || ""}
                    onChange={handleChange}
                  />
                </div>
                <div className="grid-template-columns-2">
                  <ItemsIncluded
                    formData={formData}
                    setFormData={(formData) => setFormData(formData)}
                    setItemsIncluded={(itemsIncluded) =>
                      setItemsIncluded(itemsIncluded)
                    }
                    itemsIncluded={itemsIncluded}
                  />
                  <Highlights
                    formData={formData}
                    setFormData={(formData) => setFormData(formData)}
                    setHighlights={(highlights) => setHighlights(highlights)}
                    highlights={highlights}
                  />
                </div>
              </div>
            </div>
            <div className="mt-4 form-group col-12 bg-gradient rounded-end">
              <SimpleImage
                title={"Imagen de Portada"}
                courseId={course && course.id}
                handleHeroImage={handleHeroImage}
                heroImage={heroImage}
              />
            </div>
            <div className="mt-4 form-group col-12 bg-gradient rounded-end">
              <ImagesUpload
                courseId={course && course.id}
                handleFileChange={handleFileChange}
                files={files}
                handleFileDelete={handleFileDelete}
              />
            </div>
            <div className="justify-content-right">
              <Button
                disabled={disabledReason()}
                onClick={handleEdit}
                className="w-200 btn btn-primary float-end d-flex justify-content-center align-items-center "
                type="submit"
              >
                Guardar datos
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default EditCourse;
