import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import './cookieConsent.css';

const CookieConsentComponent = () => {
  const [showConsent, setShowConsent] = useState(true);

  useEffect(() => {
    // Verificar si el usuario ha aceptado previamente
    const hasConsent = localStorage.getItem("cookieConsent");
    if (hasConsent) {
      setShowConsent(false);
    }
  }, []);

  const handleAccept = () => {
    // Guardar la elección en el local storage
    localStorage.setItem("cookieConsent", "true");
    setShowConsent(false);
  };

  return (
    <div className={`cookie-consent ${showConsent ? "show" : "hide"}`}>
      <div className="cookie-content">
        <p>
          Este sitio web utiliza cookies para asegurarse de obtener la mejor experiencia en nuestro sitio web. Conoce nuestras 
          <a href='https://www.muerdete.com/politicas-privacidad' hTarget="_blank"> Políticas de Privacidad</a>
        </p>
        <button onClick={handleAccept}>Aceptar</button>
      </div>
    </div>
  );
};

export default CookieConsentComponent;

