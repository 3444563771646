import Navbar from "../components/navbar";
import image1 from "../assets/images/nuevas/ESPACIO1.jpg";
import image2 from "../assets/images/nuevas/ESPACIO2.jpg";
import image3 from "../assets/images/nuevas/ESPACIO3.jpg";
import image4 from "../assets/images/nuevas/ESPACIO4.jpg";
import "./nuestroEspacio.css";

export const NuestroEspacio = () => {
  return (
    <>
      <Navbar />
      <div className="container mt-5 mb-5">
        <h3>Explora Nuestro Espacio de Cocina</h3>
        <p>Sumérgete en el corazón de nuestra cocina, un espacio diseñado para inspirar, aprender y crear juntos. Aquí es donde la pasión por la cocina se encuentra con la camaradería. ¿Qué hace único a nuestro espacio?</p>

        <ul style={{ paddingInlineStart: '20px' }}>
          <li style={{ marginBottom: '10px' }}><strong>Equipamiento de Última Generación:</strong> Nuestra cocina está equipada con tecnología de vanguardia para brindarte una experiencia culinaria sin igual.</li>
          <li style={{ marginBottom: '10px' }}><strong>Ambiente Creativo:</strong> Cada rincón ha sido diseñado para inspirar la creatividad y el trabajo en equipo, proporcionando el entorno perfecto para tus eventos de team building.</li>
          <li style={{ marginBottom: '10px' }}><strong>Espacio Versátil:</strong> Adaptamos nuestro espacio para satisfacer las necesidades de tu grupo, desde eventos corporativos hasta reuniones de equipo y salidas divertidas.</li>
        </ul>
      </div>

      <div className="card p-lg-6">
        <div className="row ">
          <div className="col-12 col-lg-5 d-flex align-items-center justify-content-center">
            <img className="w-100 rounded-2" src={image1} alt={image1} />
          </div>
          <div className="col-12 col-lg-7 d-flex align-items-center justify-content-center">
            <img className="w-100 rounded-2" src={image2} alt={image2} />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-7 d-flex align-items-center justify-content-center">
            <img className="w-100 rounded-2" src={image3} alt={image3} />
          </div>
          <div className="col-12 col-lg-5 d-flex align-items-center justify-content-center">
            <img className="w-100 rounded-2" src={image4} alt={image4} />
          </div>
        </div>
      </div>
    </>
  );
};

export default NuestroEspacio;
