import React from "react";
import { Form, Input, Button, notification } from "antd";
import axios from "axios";
import backgroundImg from "../../../assets/images/hero/yellow_background.png";

const LoginForm = ({ setIsLogged }) => {
  const [api, contextHolder] = notification.useNotification();

  const onFinish = (values) => {
    const loginData = {
      email: values.email,
      password: values.password,
    };
    axios
      .post(process.env.REACT_APP_URI + `/v1/auth/login`, loginData)
      .then((response) => {
        if (!!response && response.status === 201) {
          setIsLogged(true);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 404) {
            api.error({
              message: `Datos incorrectos`,
              description:
                "Los datos ingresados son incorrectos. Por favor, verifica la información proporcionada e inténtalo nuevamente.",
              placement: "bottomRight",
            });
          }
        }
      });
  };
  const styles = {
    container: {
      backgroundImage: "url(" + backgroundImg + ")",
      minHeight: "93vh",
      overflor: "hidden",
      display: "flex",
      alignItems: "center",
    },
  };

  return (
    <>
      {contextHolder}
      <div style={styles.container}>
        <Form
          className="card border col-lg-4 col-10 mx-auto"
          onFinish={onFinish}
        >
          <h6 className="border-bottom p-lg-3">Iniciar sesión</h6>
          <div className="card-body">
            <div className="form-group">
              <label>Email</label>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Por favor, ingresa tu email." },
                  {
                    min: 4,
                    message: "El email debe tener al menos 4 carácteres.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="form-group">
              <label>Contraseña</label>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Por favor, ingresa tu contraseña.",
                  },
                  {
                    min: 6,
                    message: "La contraseña debe tener al menos 6 carácteres.",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
            </div>

            <Form.Item>
              <button type="submit" className="w-100 btn btn-primary mb-0">
                Ingresar
              </button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </>
  );
};

export default LoginForm;
