import React from "react";
import { Document, Image, Link, Page, StyleSheet, Text, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    height: "100vh",
    width: '100%'
  },
  page: {
    flexDirection: "column",
    width: "100%",
    height: "100%",

  },
  title: {
    fontSize: 104,
    lineHeight: 18,
    fontWeight: "bold",
    marginBottom: 8,
  },
  date: {
    fontSize: 32,
    fontWeight: "bold",
    marginBottom: 6,
  },
  hour: {
    fontSize: 32,
    fontWeight: "bold",
    marginBottom: 32,
  },
  data: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 8,
  },
  footer: {
    backgroundColor: '#f8d565',
    height: '25vh',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
    paddingTop: '1rem',
    width: '100%',
    marginTop: 0,

  },
  forMoreDetails: {
    fontWeight: "bold",
    marginBottom: 24,
  },
  header: {
    backgroundColor: '#f8d565',
    height: '25vh',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: 0
  },
  body: {
    height: '50vh',
    fontSize: 20,
    color: '#000000',
    padding: 40,
    fontSize: 22,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  textFooter: {
    fontSize: '12px',
    padding: 40
  },
  linkFooter: {
    fontSize: 18
  },


});

const StyledDiv = ({ data }) => (
  <View style={styles.container}>
    <View style={styles.header}>
      <Image style={styles.image} source={'https://i.postimg.cc/gcPrH4hg/logo.png'} />
    </View>
    <View style={styles.body}>
    <Text>
        Hola {data?.name},
      </Text>
      <Text>
        Tu reserva para {data.quantity} personas el {data?.title} el dia {data?.lessonDate} a las {data?.startHour} se ha generado con éxito.
      </Text>
      <Text style={styles.textFooter}>
        {data.message}
      </Text>
    </View>
    <View style={styles.footer}>
      <Link style={styles.linkFooter} src=" https://g.co/kgs/T3Nmw7h">Ubicación : C / Casanova N°3 . Local</Link>
    </View>
  </View >

);

const PDFDocument = ({ data }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <StyledDiv data={data} />
      </Page>
    </Document>
  );
};

export default PDFDocument;
