import axios from 'axios'
import React, { Fragment, useEffect, useState } from 'react'


const VoucherInformation = ({ voucherInformation }) => {
  const [courseFromRequest, setCouseFromRequest] = useState()

  useEffect(() => {
    getCourseById()
  }, [])

  const getCourseById = async () => {
    try {
      const courseRequested = await axios.get(process.env.REACT_APP_URI + `/v1/courses/${voucherInformation.courseId}`)
      setCouseFromRequest(courseRequested.data)
    } catch (error) {
      console.log("error", error)
    }
  }

  const changeFormatDateDDMMYYYHHMM = dateTime => {
    const hoursDate = new Date(dateTime).getHours();
    let minDate = new Date(dateTime).getMinutes().toString();
    const TEN_MINUTES = 10;
    if (Number(minDate) < TEN_MINUTES) {
      minDate = '0' + minDate.toString();
    }
    const formattedDate = new Date(dateTime).toLocaleDateString();
    const newModifiedDate = formattedDate + ' ' + hoursDate + ':' + minDate;

    return newModifiedDate;
  };



  return (
    <div className="card shadow-xs border">
      <h6 class="border-bottom p-lg-3">Información del voucher</h6>
      <div className="card-body p-lg-3">
        <ul className="list-unstyled">
          <li>
            <div className="d-flex justify-content-between">
              <p className="opacity-8">Taller</p>
              <p className="fw-bold opacity-8">
                {courseFromRequest?.title || courseFromRequest?.label}
              </p>
            </div>
          </li>
          <li className="mt-2">
            <div className="d-flex justify-content-between">
              <p className="opacity-8">Source</p>
              <p className="fw-bold opacity-8">
                {
                  voucherInformation.source
                }
              </p>
            </div>
          </li>
          <li className="mt-2">
            <div className="d-flex justify-content-between">
              <p className="opacity-8">Localizador/Tracker</p>
              <p className="fw-bold opacity-8">
                {voucherInformation.tracker}
              </p>
            </div>
          </li>
          <li className="mt-2">
            <div className="d-flex justify-content-between">
              <p className="opacity-8">Código</p>
              <p className="fw-bold opacity-8">
                {voucherInformation.code}
              </p>
            </div>
          </li>
          <li className="mt-2">
            <div className="d-flex justify-content-between">
              <p className="opacity-8">Fecha de caducidad</p>
              <p className="fw-bold opacity-8">
                {changeFormatDateDDMMYYYHHMM(voucherInformation.expiryDate)}
              </p>
            </div>
          </li>
          <li className="mt-2">
            <div className="d-flex justify-content-between">
              <p className="opacity-8">Fecha de compra</p>
              <p className="fw-bold opacity-8">
                {changeFormatDateDDMMYYYHHMM(voucherInformation.purchaseDate)}
              </p>
            </div>
          </li>
          <li className="mt-2">
            <div className="d-flex justify-content-between">
              <p className="opacity-8">Cantidad de personas</p>
              <p className="fw-bold opacity-8">
                {voucherInformation.seatsQty}
              </p>
            </div>
          </li>
          <li className="mt-2">
            <div className="d-flex justify-content-between">
              <p className="opacity-8">Estado del voucher</p>
              <p className="fw-bold opacity-8">
                Sin Uso
              </p>
            </div>
          </li>
          <h6 class="border-bottom p-lg-3">Datos del alumno</h6>
          <li className="mt-2">
            <div className="d-flex justify-content-between">
              <p className="opacity-8">Nombre</p>
              <p className="fw-bold opacity-8">
                {voucherInformation.buyer.name}
              </p>
            </div>
          </li>
          <li className="mt-2">
            <div className="d-flex justify-content-between">
              <p className="opacity-8">Email</p>
              <p className="fw-bold opacity-8">
                {voucherInformation.buyer.email}
              </p>
            </div>
          </li>
          <li className="mt-2">
            <div className="d-flex justify-content-between">
              <p className="opacity-8">Teléfono de contacto</p>
              <p className="fw-bold opacity-8">
                {voucherInformation.buyer.phone}
              </p>
            </div>
          </li>
          <li className="mt-2">
            <div className="d-flex justify-content-between">
              <p className="opacity-8">Fecha de Cumpleaños</p>
              <p className="fw-bold opacity-8">
                {voucherInformation.buyer.birthdate}
              </p>
            </div>
          </li>
          {voucherInformation.buyer.specialRequests && (
            <li className="mt-2">
              <div className="d-flex justify-content-between">
                <p className="opacity-8">Pedidos Especiales</p>
                <div className='d-flex gap-2'>
                  {voucherInformation.buyer.specialRequests.map((el, index) => (
                    <Fragment key={Math.random()}>
                      <p className="fw-bold opacity-8">
                        {el}
                      </p>
                      {index + 1 !== voucherInformation.buyer.specialRequests.length && (
                        <p className="opacity-8">,</p>
                      )}
                    </Fragment>
                  ))}
                </div>
              </div>
            </li>
          )}

        </ul>
      </div>
    </div>

  )
}

export default VoucherInformation