import { PDFDownloadLink } from "@react-pdf/renderer";
import Confetti from "react-confetti-explosion";
import { useLocation } from "react-router-dom";
import purchaseSuccess from "../../assets/images/hero/success_purchase.svg";
import Navbar from "../navbar";
import PDFDocument from "../../hooks/data/pdf-document";
import useGetCourses from "../../hooks/data/use-get-courses";
import { useEffect, useState } from "react";

const PurchaseSuccess = () => {
  const styles = {
    background: {
      minHeight: "85vh",
    },
  };
  const location = useLocation();

  const data = location.state?.data || null;
  const pdfData = { ...data, lessonDate: data?.lessonDate.slice(0, 10) };


  return (
    <>
      <Confetti
        width={3000}
        height={3000}
        particleCount={2000}
        force={1}
        duration={8000}
      />
      <Navbar />
      <div class="page-header py-5 py-md-0" style={styles.background}>
        <div className="container text-center col-lg-6 col-sm-12">
          <div className="mx-auto">
            <img src={purchaseSuccess} width={200} />
          </div>
          <div className="col-lg-8 mx-auto">
            <h2 class="mb-2">Reserva exitosa</h2>
            <p>Su reserva se ha realizado correctamente.</p>
            <PDFDownloadLink
              document={<PDFDocument data={pdfData} />}
              fileName="information.pdf"
              className="btn btn-white btn-lg"
            >
              {({ blob, url, loading, error }) =>
                loading
                  ? "Generando el PDF..."
                  : error
                  ? "Hubo un error"
                  : "Descargar reserva"
              }
            </PDFDownloadLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default PurchaseSuccess;
