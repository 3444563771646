import { useEffect, useState } from "react";
import axios from "axios";

const useGetCourses = () => {
  const [courses, setCourses] = useState();
  useEffect(() => {
    axios.get(process.env.REACT_APP_URI + "/v1/courses").then((response) => {
      setCourses(response.data);
    });
  }, []);
  return courses;
};

export default useGetCourses;
