import { useParams } from 'react-router-dom';
import { S3_BUCKET_NAME } from '../../utils/constants';
import useGetCourses from '../../hooks/data/use-get-courses';
import { useState, useEffect } from 'react';

export default function OrderSummary({
  textColor,
  classSelected,
  quantity,
}) {


  const [courseToBuy, setCourseToBuy] = useState()
  const courses = useGetCourses();

  const courseId = useParams().courseId
  useEffect(() => {
    getCourseFromTitle()
  }, [courseId])



  const getCourseFromTitle = () => {
    const courseIdLowerCase = courseId.toLowerCase().replace(/-/g, ' ');
    const courseFilteredArray = courses?.filter((course) =>
      course.title.toLowerCase().replace(/-/g, ' ') === courseIdLowerCase
    );
    setCourseToBuy(courseFilteredArray && courseFilteredArray[0]);
  };

  useEffect(() => {
    getCourseFromTitle()
  }, [courses])


  let variant = "";

  if (textColor) {
    variant = `text-${textColor}`;
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    
    if (isNaN(date.getTime())) {
      return 'Fecha inválida';
    }
  
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
  
    return `${day}-${month}-${year}`;
  }
  

 
 
  return (
    <>
      <ul className="list-unstyled">
        <li className="border-bottom py-3">
          <div className="d-flex gap-3">
            <img className="w-70-px h-70-px fit-cover rounded-3 shadow-xs border" src={`${S3_BUCKET_NAME}/${courseToBuy?.id}/${courseToBuy?.images[0]}`} alt='logo' />
            <div className='w-100'>
              {
                (courseToBuy ) &&
                <div className='text-2xl'>
                  <b>{( courseToBuy && courseToBuy.title) }</b>
                </div>
              }
              {(classSelected?.lessonDate) &&
                <>
                  <div className="d-flex justify-content-between">
                    Fecha:
                    <h6 className="text-md">{formatDate(classSelected?.lessonDate)}</h6>
                  </div>

                  <div className="d-flex justify-content-between">
                    Hora:
                    <h6 className="text-md"> {classSelected?.startHour} - {classSelected?.finishHour}</h6>
                  </div>
                </>
              }
            </div>
          </div>
        </li>
        <li className="mt-3">
          <div className="d-flex justify-content-between">
            <h5 className={variant}>Orden Total</h5>
            <h5 className={variant}>€{quantity === 1 ? +courseToBuy?.price : +courseToBuy?.price2 * quantity}</h5>
          </div>
        </li>
      </ul>
    </>
  );
}