import { PlusCircleOutlined } from "@ant-design/icons";

const Highlights = ({ setHighlights, highlights, setFormData }) => {
  const addHighlight = () => {
    if (highlights?.length < 5) {
      setHighlights([...highlights, ""]);
    } else if (!highlights) {
      setHighlights([""]);
    }
  };
  const handleInputChange = (index, value) => {
    const updatedInputs = highlights && [...highlights];
    updatedInputs[index] = value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      highlights: updatedInputs,
    }));
  };

  return (
    <div className="form-group">
      <label>Informacion adicional</label>
      {highlights?.map((value, index) => (
        <input
          className="form-control mb-2"
          placeholder="Informacion adicional"
          key={index}
          defaultValue={value}
          name="highlights"
          onChange={(e) => handleInputChange(index, e.target.value)}
        />
      ))}
      <button className="btn text-lg text-primary" onClick={addHighlight}>
        <PlusCircleOutlined />
      </button>
    </div>
  );
};

export default Highlights;
