import { useEffect } from "react";
import { useParams } from "react-router-dom";
import '../assets/scss/astro-ecommerce.scss';
import Navbar from '../components/navbar';
import ProductOverviewGrid from '../components/products/productOverviewGrid';

export const Product = ({ setCourseId, course }) => {
  const { courseId } = useParams()

  useEffect(() => {
    setCourseId(courseId)
  }, [courseId])

  return (
    <>
      <Navbar />
      {course &&
        <ProductOverviewGrid
          course={course}
        />
      }
    </>
  )
}
export default Product