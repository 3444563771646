import { useEffect, useState } from 'react';
import axios from 'axios';

const useGetPaymentDetails = (paymentId) => {
    const [paymentDetails, setPaymentDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (paymentId) {
            setLoading(true);
            axios.get(`${process.env.REACT_APP_URI}/v1/payments/${paymentId}`)
                .then(response => {
                    setPaymentDetails(response.data);
                })
                .catch(err => {
                    setError(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [paymentId]);

    return { paymentDetails, loading, error };
};

export default useGetPaymentDetails;
