import React, { useEffect } from "react";
import { ConfigProvider, DatePicker } from "antd";
import moment from "moment";
import useGetLessons from "../../../hooks/data/use-get-lessons";
import esES from 'antd/es/locale/es_ES'; // Importa el idioma español
import 'moment/locale/es'; // Importa el idioma de moment.js en español

const DateSelector = ({
  setDateSelectedLessons,
  setSelectedDate,
  selectedDate,
}) => {
  const lessons = useGetLessons();

  moment.locale('es', {
    week: {
      dow: 1, 
    },
  });


  const handleDateChange = (date, dateString) => {
    const formattedDate = moment(dateString).format('YYYY-MM-DD');
    setSelectedDate(formattedDate);
    localStorage.setItem('selectedDate', formattedDate);
  };


  useEffect(() => {
    setDateSelectedLessons(
      lessons?.filter(
        (l) =>
          moment(l?.lessonDate).format("YYYY-MM-DD") ===
          moment(selectedDate).format("YYYY-MM-DD")
      )
    );
  }, [lessons, selectedDate]);

  return (
    <ConfigProvider locale={esES} >
      <DatePicker placeholder="Seleccionar fecha" onChange={handleDateChange} />
    </ConfigProvider>
  );
};

export default DateSelector;


