import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Image } from "antd";
import { useEffect, useState } from "react";
import { S3_BUCKET_NAME } from "../../../../utils/constants";


const ImagesUploader = ({ files, handleFileChange, handleFileDelete, courseId }) => {


  const handleImageChange = (event) => {
    handleFileChange(Array.from(event.target.files));
  };

  const deleteItem = (index) => {
    const filesFiltered = files.filter((_, i) => i !== index);
    handleFileDelete(filesFiltered);
  };

  const styles = {
    input: {
      display: "none",
      position: "absolute",
      zIndex: "-1",
    },
  };

  const acceptedFiles = ['image/jpeg', 'image/png'];

  return (
    <div className="card border">
      <div className="d-flex justify-content-between border-bottom p-lg-3">
        <h6>Imágenes</h6>
        <div className="btn m-0">
          <input
            name="images"
            style={styles.input}
            type="file"
            id="file-upload"
            multiple
            accept={acceptedFiles}
            onChange={handleImageChange}
          />
          <label className="cursor-pointer" htmlFor="file-upload"> {/* Corregir aquí */}
            <PlusOutlined /> Agregar
          </label>
        </div>
      </div>
      <div className="card-body">
        <div className="grid-template-columns-4 mb-2">

          {files?.map((image, index) => (
            <div key={index} className="image-item border-radius-md border p-lg-2">
              {typeof image === 'string' ? (
                <Image
                  alt={`Image ${index}`}
                  style={{ height: '200px' }}
                  src={`${S3_BUCKET_NAME}/${courseId}/${image}`}
                  className="border-radius-md image"
                />
              ) : (
                <Image
                  alt={`Image ${index}`}
                  style={{ height: '200px' }}
                  src={URL.createObjectURL(image)}
                  className="border-radius-md image"
                />
              )}
              <button
                className="btn bg-transparent border mt-2 w-100"
                onClick={() => deleteItem(index)}
              >
                <DeleteOutlined /> Delete
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImagesUploader;
