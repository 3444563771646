import purchaseError from "../../assets/images/hero/error_purchase.svg";
import Navbar from "../navbar";

const PurchaseError = () => {
  const styles = {
    background: {
      minHeight: "100%",
    },
  };
  
  return (
    <>
      <Navbar />
      <div class="page-header py-5 py-md-0" style={styles.background}>
        <div className="container text-center col-lg-6 col-sm-12">
          <div className="mx-auto">
            <img src={purchaseError} width={400} />
          </div>
          <div className="col-lg-8 mx-auto">
            <h2 class="mb-2">Algo ha salido mal</h2>
            <p>
              Lo sentimos, ha ocurrido un problema con tu reserva. Por favor,
              contáctanos al{" "}
              <a
                href="https://api.whatsapp.com/send/?phone=%2B34673553301&text=Quiero+saber+mas+sobre+el+taller+grupal"
                target="_blank"
              >
                673553301
              </a>{" "}
              para resolverlo. Disculpa las molestias.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PurchaseError;
