import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Input, Modal, Button, notification } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import useGetCourses from "../../../hooks/data/use-get-courses";
import { S3_BUCKET_NAME } from "../../../utils/constants";

const { confirm } = Modal;

const suffix = (
  <SearchOutlined
    style={{
      fontSize: 16,
      color: "#1677ff",
    }}
  />
);

const CoursesTable = () => {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const courses = useGetCourses();
  const [filteredData, setFilteredData] = useState();
  useEffect(() => {
    setFilteredData(courses);
  }, [courses]);

  const onChange = (e) => {
    const searchTerm = e.target.value;
    const filteredItems = courses?.filter((item) => {
      return item.title?.toLowerCase().includes(searchTerm?.toLowerCase());
    });
    setFilteredData(filteredItems);
  };

  const handleDelete = (id) => {
    axios.delete(process.env.REACT_APP_URI+`/v1/courses/${id}`).then(() => {
      api.success({
        message: `Taller eliminado`,
        description: "El taller ha sido eliminado con éxito.",
        placement: "bottomRight",
      })
      setTimeout(() =>  window.location.reload(), 1700);
    });
    const updatedCourses = courses?.filter((course) => course.id !== id);
    setFilteredData(updatedCourses);
  };

  const showPropsConfirm = (course) => {
    confirm({
      title: "¿Está seguro que desea borrar este taller?",
      icon: <ExclamationCircleFilled />,
      content:
        "Se borrarán todos los datos del taller, las lecciones y alumnos asociados a este.",
      okText: "Si",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(course?.id);
      },
      onCancel() {},
    });
  };


  return (
    <div>
      {contextHolder}
      <div className="d-flex gap-3 my-3">
        <Input
          suffix={suffix}
          placeholder="Buscar taller"
          onChange={onChange}
          style={{
            width: 200,
            height: 40,
          }}
        />
        <Link to={`/backoffice/crear-curso`}>
          <button className="btn btn-primary">Agregar taller</button>
        </Link>
      </div>
      <div className="grid-template-columns-3">
        {filteredData?.map((course) => (
          <>
            <div className={`card border-1 p-lg-3 card-product card-plain d-flex ${course.isPrivate && 'bg-warning' }`}>
              <div className="d-block d-md-flex justify-content-between">
                <div>
                  <img
                    className="w-50-px rounded-circle shadow-xs"
                    src={`${S3_BUCKET_NAME}/${course.id}/${course.images[0]}`}
                    alt='logo'
                  />
                </div>
                <div className="col-6 col-md-6">
                  <h5 className="text-base mb-1">{course?.title}</h5>
                  <h6 className="text-sm font-weight-bold mb-0">
                    {course?.price?.toFixed(2)} €
                  </h6>
                </div>
                <div>
                  <div className="d-flex">
                    <Link to={`/backoffice/editar/${course?.id}`}>
                      <button className="border-none bg-transparent">
                        <i className="fas fa-edit d-block d-md-none me-2"></i>
                        <h6 className="text-primary text-sm pe-3 border-end d-none d-md-block">
                          <EditOutlined />
                        </h6>
                      </button>
                    </Link>

                    <Button
                      onClick={() => showPropsConfirm(course)}
                      className="border-none bg-transparent"
                    >
                      <i className="fas fa-times d-block d-md-none ms-2"></i>
                      <h6 className="text-danger text-sm d-none d-md-block">
                        <DeleteOutlined />
                      </h6>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  );
};
export default CoursesTable;
