import { useState } from "react";
import { Collapse, Input, notification, Select } from "antd";
import DateSelection from "../../booking/dateSelection";
import PersonalData from "../../booking/personalData";
import Navbar from "../../navbar";
import axios from "axios";
import useGetCourses from "../../../hooks/data/use-get-courses";
import useGetLessons from "../../../hooks/data/use-get-lessons";
import { useGetVouchers } from "../../../hooks/data/use-crud-vouchers";
import { useLocation, useNavigate } from "react-router-dom";
import CarouselComponent from "../../../components/booking/stepsCarousel";
import VoucherInformation from "../vouchers/vouchersInformation";
import { LeftOutlined } from "@ant-design/icons";


const VOUCHER_STATUS_INACTIVE = 7;
const VOUCHER_STATUS_USED = 6;
const VOUCHER_STATUS_ANULADO = 2;
const VOUCHER_STATUS_ABIERTO = 1;
const AddBooking = () => {
  const navigate = useNavigate();
  const courses = useGetCourses();
  const lessons = useGetLessons();
  const vouchers = useGetVouchers();
  const [api, contextHolder] = notification.useNotification();
  const [course, setCourse] = useState();
  const [personalData, setPersonalData] = useState({
    name: "",
    email: "",
    phone: "",
    birthDate: "",
    specialRequests: []
  });
  const [classSelected, setClassSelected] = useState();
  const [useVoucher, setUseVoucher] = useState(false);
  const [createVoucher, setCreateVoucher] = useState(false);
  const [voucherId, setVoucherId] = useState();
  const [quantitySeats, setQuantitySeats] = useState();
  const [aladiniaVoucherData, setAladiniaVoucherData] = useState();
  const [atrapaloVoucherData, setAtrapaloVoucherData] = useState();
  const [muerdeteVoucherData, setMuerdeteVoucherData] = useState();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [voucherIsCreated, setVoucherIsCreated] = useState(false)
  const [otherRequest, setOtherRequest] = useState('');
  const [aladiniaGenericInfo, setAladiniaGenericInfo] = useState(false);
  const [selectedGenericCourse, setSelectedGenericCourse] = useState();

  const { Panel } = Collapse;
  const [voucherData, setVoucherData] = useState({
    source: "",
    tracker: "",
    code: "",
  });
  const handleVoucherDataChange = (e) => {
    setVoucherData({
      ...voucherData,
      [e.target.name]: e.target.value,
    });
  };
  const openNotification = (message, description, placement) => {
    api[message.type]({
      message: message.content,
      description,
      placement,
    });
  };
  const handleAladiniaCase = async () => {
    try {
      let bono = await axios.get(
        `${process.env.REACT_APP_URI}/v1/voucher/${voucherData.tracker}`
      );
      bono = bono.data;


      if (bono.status_id === VOUCHER_STATUS_ABIERTO) {
        const selectedCourse = findCourseByService(bono.service);
        const seats = findCourseSeats(bono.people);

        personalData.specialRequests.push(otherRequest)
        setAladiniaVoucherData({
          source: voucherData.source,
          tracker: voucherData.tracker,
          code: voucherData.code,
          purchaseDate: bono.purchase_date,
          expiryDate: bono.valid_until,
          courseId: selectedGenericCourse || selectedCourse.id,
          seatsQty: seats,
          used: false,
          status: bono.status_id,
          statusName: bono.status,
          buyer: {
            name: personalData?.name,
            email: personalData?.email,
            phone: `+34${personalData?.phone}`.replace(/\s/g, ''),
            birthdate: personalData?.birthDate,
            specialRequests: personalData?.specialRequests,
          },
        });
        setCourse(selectedCourse);
      } else if (bono.status_id === VOUCHER_STATUS_USED) {
        openNotification(
          { type: "warning", content: "bono ya utilizado" },
          "Este bono ya ha sido utilizado anteriormente.",
          "bottomRight"
        );
      } else if (bono.status_id === VOUCHER_STATUS_ANULADO) {
        openNotification(
          { type: "warning", content: "bono anulado" },
          "Este bono ha sido anulado.",
          "bottomRight"
        );
      } else if (bono.status_id === VOUCHER_STATUS_INACTIVE) {
        openNotification(
          { type: "warning", content: "bono inactivo" },
          "Este bono no puede ser utilizado. Comunicate con Aladinia.",
          "bottomRight"
        );
      } else {
        openNotification(
          { type: "error", content: "bono no encontrado" },
          "No pudimos encontrar tu bono",
          "bottomRight"
        );
      }
    } catch (error) {
      console.error("Error al hacer la consulta a aladinia:", error);
    }
  };

  const onSaveCreateBooking = () => {
    const postData = {
      lessonId: classSelected?.id,
      voucherId: voucherId,
      name: personalData?.name,
      email: personalData?.email,
      phone: `+34${personalData?.phone}`.replace(/\s/g, ''),
      birthdate: personalData?.birthDate,
      specialRequests: personalData?.specialRequests,
    };

    axios
      .post(
        process.env.REACT_APP_URI +
        `/v1/lessons/${classSelected?.id}/voucher/${voucherId}/client`,
        postData
      )
      .then((response) => {
        if (response.status === 201) {
          openNotification(
            { type: "success", content: "Reserva creada" },
            "La reserva se ha creado exitosamente.",
            "bottomRight"
          );
          setTimeout(() => navigate("/backoffice"), 3000);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 404 || error.response.status === 409) {
            api.error({
              message: `Algo ha salido mal`,
              description: "Por favor introduzca los datos nuevamente.",
              placement: "bottomRight",
            });
          } else {
            api.error({
              message: `Ha ocurrido un error en el servidor.`,
              description: `Por favor inténtelo nuevamente o <a href="tel:34673553301">contactanos al 34673553301.</a>.`,
              placement: "bottomRight",
            });
            setTimeout(() => navigate("/reserva/error"), 3000);
          }
        }
      });
  };
  const handleCreateVoucher = () => {
    // caso Aladinia
    if (voucherData.source === "aladinia") {
      aladiniaVoucherData.courseId = selectedGenericCourse || aladiniaVoucherData.courseId;
      axios
        .post(`${process.env.REACT_APP_URI}/v1/vouchers`, aladiniaVoucherData)
        .then((response) => {
          if (response.status === 201) {
            setShowDatePicker(true);
            setVoucherId(response.data.id);
            setCourse(selectedGenericCourse || aladiniaVoucherData.courseId)
          }
        });
    }
    // caso Atrapalo
    if (voucherData.source === "atrapalo") {
      personalData.specialRequests.push(otherRequest)
      const newVoucher = axios
        .post(`${process.env.REACT_APP_URI}/v1/vouchers`, {
          source: voucherData.source,
          tracker: voucherData.tracker,
          code: voucherData.code,
          courseId: course.value,
          used: false,
          seatsQty: quantitySeats,
          buyer: {
            name: personalData?.name,
            email: personalData?.email,
            phone: `+34${personalData?.phone}`.replace(/\s/g, ''),
            birthdate: personalData?.birthDate,
            specialRequests: personalData?.specialRequests,
          },
        })
        .then((response) => {
          if (response.status === 201) {
            setAtrapaloVoucherData(response.data);
            setVoucherId(response.data.id);
            setVoucherIsCreated(true);
            setShowDatePicker(true)
          }
        });
    }
    if (voucherData.source === "muerdete") {
      personalData.specialRequests.push(otherRequest)
      const newVoucher = axios
        .post(`${process.env.REACT_APP_URI}/v1/vouchers`, {
          source: voucherData.source,
          courseId: course.value,
          used: false,
          seatsQty: quantitySeats,
          buyer: {
            name: personalData?.name,
            email: personalData?.email,
            phone: `+34${personalData?.phone}`.replace(/\s/g, ''),
            birthdate: personalData?.birthDate,
            specialRequests: personalData?.specialRequests,
          },
        })
        .then((response) => {
          if (response.status === 201) {
            setShowDatePicker(true);
            setMuerdeteVoucherData(response.data);
            setVoucherId(response.data.id);
            setVoucherIsCreated(true);
          }
        });
    }
  };

  const findCourseByService = (service) => {
    let selectedCourse;
    if (service.toLowerCase().includes("elegir")) {
      setAladiniaGenericInfo(true)
    }
    else if (
      service.toLowerCase().includes("sushi") ||
      service.toLowerCase().includes("japonesa")
    ) {
      selectedCourse = courses?.find((course) =>
        course.title.toLowerCase().includes("sushi")
      );
    } else if (
      service.toLowerCase().includes("mexicano") ||
      service.toLowerCase().includes("mexicana")
    ) {
      selectedCourse = courses?.find((course) =>
        course.title.toLowerCase().includes("mexicano")
      );
    } else if (service.toLowerCase().includes("pasta")) {
      selectedCourse = courses?.find((course) =>
        course.title.toLowerCase().includes("pasta")
      );
    }

    return selectedCourse;
  };
  const findCourseSeats = (people) => {
    let seats;
    if (people.toLowerCase().includes("dos personas")) {
      seats = 2;
    } else if (people.toLowerCase().includes("una persona")) {
      seats = 1;
    }
    return seats;
  };


  const onVoucherSearch = () => {
    switch (voucherData.source) {
      case "muerdete":
      // handleMuerdeteCase();
      // break;
      case "aladinia":
        handleAladiniaCase();
        break;
      default:
        // Otros casos
        break;
    }
  };



  const coursesArray = courses?.map((course) => {
    return {
      value: course.id,
      label: course.title,
    };
  });

  const handleOtherRequestChange = (value) => {
    setOtherRequest(value);
  };

  const handlePersonalDataChange = (e) => {
    setPersonalData({
      ...personalData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSpecialRequestsChange = (selectedValues) => {
    setPersonalData({
      ...personalData,
      specialRequests: selectedValues,
    });
  };

  const handleBirthdateSelection = (dateString) => {
    setPersonalData({
      ...personalData,
      birthDate: dateString,
    })
  }


  const disabledReason = () => {
    return (!course || !quantitySeats || !personalData.name || !personalData.email || !personalData.phone || !personalData.birthDate)
  }


  const disabledReasonSearchVoucher = () => {
    const isAladinia = voucherData.source === 'aladinia';

    if (isAladinia && aladiniaGenericInfo) {
      return (!voucherData.source || !voucherData.tracker || !selectedGenericCourse);
    }

    if (isAladinia && !aladiniaGenericInfo) {
      return (!voucherData.source || !voucherData.tracker);
    }
  }

  const disableCreateReserve = () => {
    return !classSelected
  }

  const handleCreateVoucherChoose = () => {
    setUseVoucher(false)
    setCreateVoucher(true)
  }

  const handleUseExistingVoucher = () => {
    setUseVoucher(true)
    setCreateVoucher(false)
  }


  return (
    <>
      <Navbar />

      {contextHolder}
      <div className="col-12 col-lg-5 col-md-6 p-3 p-md-5 d-flex flex-column flex-nowrap">
        <div className="cursor-pointer d-flex align-items-center gap-2 mb-4">
          <LeftOutlined />
          <a onClick={() => window.history.back()}>Volver</a>
        </div>
        {
          !voucherIsCreated
            ?
            <div className="card shadow-xs border mb-4">
              <h6 class="border-bottom p-lg-3">Búscar bono</h6>
              <div className="card-body">
                <div className="form-group d-grid">
                  <label>Plataforma de bono</label>
                  <Select
                    name="source"
                    className="w-100"
                    placeholder="Seleccionar"
                    optionFilterProp="children"
                    onChange={(value) =>
                      setVoucherData({
                        ...voucherData,
                        source: value,
                      })
                    }
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        ?.toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[
                      {
                        value: "aladinia",
                        label: "Aladinia",
                      },
                      {
                        value: "muerdete",
                        label: "Muérdete",
                      },
                      {
                        value: "atrapalo",
                        label: "Atrápalo",
                      },
                    ]}
                  />
                </div>
                {voucherData?.source === "muerdete" && (
                  <div className="d-flex gap-3">
                    <button
                      onClick={handleUseExistingVoucher}
                      className="btn bg-dark bg-gradient text-white w-50"
                    >
                      Usar bono existente
                    </button>
                    <button
                      onClick={handleCreateVoucherChoose}
                      className="btn bg-green w-50"
                    >
                      Crear bono
                    </button>
                  </div>
                )}
                {(voucherData?.source === "aladinia" ||
                  voucherData?.source === "atrapalo") && (
                    <>
                      <div class="form-group">
                        <label>Localizador</label>
                        <Input
                          name="tracker"
                          onChange={(e) => handleVoucherDataChange(e)}
                          placeholder="123456"
                        />
                      </div>
                      <div class="form-group">
                        <label>Código de reserva</label>
                        <Input
                          name="code"
                          onChange={(e) => handleVoucherDataChange(e)}
                          placeholder="123456"
                        />
                      </div>
                    </>
                  )}
                {(voucherData?.source === "atrapalo" || createVoucher === true) && (
                  <div className="form-group d-grid">
                    <label>Taller</label>
                    <Select
                      placeholder="Seleccionar taller"
                      name="course"
                      onChange={(e) =>
                        setCourse(coursesArray?.find((c) => c.value === e))
                      }
                      options={coursesArray}
                    />
                  </div>
                )}
                {
                  aladiniaGenericInfo &&
                  <div className="form-group">
                    <label>Talleres disponibles</label>
                    <Select
                      name="source"
                      className="w-100"
                      placeholder="Seleccionar"
                      optionFilterProp="children"
                      onChange={value => setSelectedGenericCourse(value)}
                      options={courses?.map(course => ({
                        value: course.id,
                        label: course.title
                      }))}
                    />
                  </div>
                }
                {voucherData?.source === "aladinia" && (
                  <button
                    disabled={disabledReasonSearchVoucher()}
                    onClick={onVoucherSearch}
                    className="w-100 btn bg-dark bg-gradient text-white"
                  >
                    {aladiniaGenericInfo ? 'Continuar' : 'Búscar Bono'}
                  </button>
                )}
                {(voucherData?.source === "atrapalo" || createVoucher === true) && (
                  <div>
                    <div class="form-group">
                      <label>Cantidad de personas</label>
                      <Input
                        type="number"
                        name="seats"
                        min={0}
                        onChange={(e) => setQuantitySeats(e.target.value)}
                        placeholder="Ingrese cantidad de personas"
                      />
                    </div>
                    <PersonalData
                      handleSpecialRequestsChange={handleSpecialRequestsChange}
                      handleBirthdateSelection={handleBirthdateSelection}
                      handleOtherRequestChange={handleOtherRequestChange}
                      otherRequest={otherRequest}
                      voucherId={voucherId}
                      classSelected={classSelected}
                      personalData={personalData}
                      handlePersonalDataChange={handlePersonalDataChange}
                    />
                    <button
                      disabled={disabledReason()}
                      onClick={handleCreateVoucher}
                      className="btn w-100 bg-dark text-white"
                    >
                      Crear bono
                    </button>
                  </div>
                )}
                {(useVoucher && voucherData.source === 'muerdete') && <CarouselComponent isMuerdeteCase={true} />}
              </div>
            </div>
            : muerdeteVoucherData && <VoucherInformation voucherInformation={muerdeteVoucherData} />
        }

        {(!!aladiniaVoucherData || !!atrapaloVoucherData) && (
          <div className="card shadow-xs border">
            <h6 class="border-bottom p-lg-3">Información del bono</h6>
            <div className="card-body p-lg-3">
              <ul className="list-unstyled">
                <li>
                  <div className="d-flex justify-content-between">
                    <p className="opacity-8">Taller</p>
                    <p className="fw-bold opacity-8">
                      {!!aladiniaVoucherData ? course?.title : course?.label}
                    </p>
                  </div>
                </li>
                <li className="mt-2">
                  <div className="d-flex justify-content-between">
                    <p className="opacity-8">Source</p>
                    <p className="fw-bold opacity-8">
                      {!!aladiniaVoucherData
                        ? aladiniaVoucherData.source
                        : atrapaloVoucherData.source}
                    </p>
                  </div>
                </li>
                <li className="mt-2">
                  <div className="d-flex justify-content-between">
                    <p className="opacity-8">Localizador/Tracker</p>
                    <p className="fw-bold opacity-8">
                      {!!aladiniaVoucherData
                        ? aladiniaVoucherData.tracker
                        : atrapaloVoucherData.tracker}
                    </p>
                  </div>
                </li>
                <li className="mt-2">
                  <div className="d-flex justify-content-between">
                    <p className="opacity-8">Código</p>
                    <p className="fw-bold opacity-8">
                      {!!aladiniaVoucherData
                        ? aladiniaVoucherData.code
                        : atrapaloVoucherData.code}
                    </p>
                  </div>
                </li>
                <li className="mt-2">
                  <div className="d-flex justify-content-between">
                    <p className="opacity-8">Fecha de caducidad</p>
                    <p className="fw-bold opacity-8">
                      {!!aladiniaVoucherData
                        ? aladiniaVoucherData.expiryDate
                        : atrapaloVoucherData.expiryDate.slice(0, 10)}
                    </p>
                  </div>
                </li>
                <li className="mt-2">
                  <div className="d-flex justify-content-between">
                    <p className="opacity-8">Fecha de compra</p>
                    <p className="fw-bold opacity-8">
                      {!!aladiniaVoucherData
                        ? aladiniaVoucherData.purchaseDate
                        : atrapaloVoucherData.purchaseDate.slice(0, 10)}
                    </p>
                  </div>
                </li>
                <li className="mt-2">
                  <div className="d-flex justify-content-between">
                    <p className="opacity-8">Cantidad de personas</p>
                    <p className="fw-bold opacity-8">
                      {!!aladiniaVoucherData
                        ? aladiniaVoucherData.seatsQty
                        : atrapaloVoucherData.seatsQty}
                    </p>
                  </div>
                </li>
                <li className="mt-2">
                  <div className="d-flex justify-content-between">
                    <p className="opacity-8">Estado del bono</p>
                    <p className="fw-bold opacity-8">
                      {!!aladiniaVoucherData
                        ? aladiniaVoucherData.statusName
                        : "Inactivo"}
                    </p>
                  </div>
                </li>
              </ul>
              {!!aladiniaVoucherData && (
                <div className="card">
                  <h6 class="border-bottom p-lg-3">Datos del alumno</h6>
                  <PersonalData
                    handleSpecialRequestsChange={handleSpecialRequestsChange}
                    handleBirthdateSelection={handleBirthdateSelection}
                    handleOtherRequestChange={handleOtherRequestChange}
                    otherRequest={otherRequest}
                    voucherId={voucherId}
                    classSelected={classSelected}
                    personalData={personalData}
                    handlePersonalDataChange={handlePersonalDataChange}
                    hideButton={true}
                  />
                </div>
              )}
              <button

                onClick={
                  !!aladiniaVoucherData
                    ? handleCreateVoucher
                    : () => setShowDatePicker(true)
                }
                className={`${voucherIsCreated && 'd-sm-none'} w-100 btn bg-dark bg-gradient text-white`}
              >
                Crear reserva usando este bono
              </button>
            </div>
          </div>
        )}
        {showDatePicker && (
          <div className="card shadow-xs border mt-4">
            <h6 class="border-bottom p-lg-3">Seleccionar fecha</h6>
            <div className="card-body p-lg-3">
              <DateSelection
                setCourse={setCourse}
                aladiniaVoucherseatsQty={aladiniaVoucherData?.seatsQty}
                atrapaloVoucherseatsQty={quantitySeats}
                setClassSelected={(classSelected) =>
                  setClassSelected(classSelected)
                }
                goToPrevious={""}
                goToNext={""}
                course={course}
                vouchers={vouchers}
                lessons={lessons}
              />
            </div>
          </div>
        )}
        {!!classSelected && (
          <div className="card shadow-xs border mt-4">
            <button
              disabled={disableCreateReserve()}
              onClick={onSaveCreateBooking}
              className="btn bg-dark w-100 text-white"
            >
              Crear reserva
            </button>
          </div>
        )}
      </div>
    </>
  );
};
export default AddBooking;