import { useState } from "react";
import {
    DatePicker,
    Input,
    TimePicker,
    notification,
} from "antd";
import { LeftOutlined } from "@ant-design/icons";
import es_ES from 'antd/es/date-picker/locale/es_ES';
import 'dayjs/locale/es';
import Navbar from "../../navbar";
import dayjs from "dayjs";
import {  useParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";



const EditLesson = () => {
    const [date, setDate] = useState();
    const [startHour, setStartHour] = useState();
    const [finishHour, setFinishHour] = useState();
    const [disableHours, setDisableHourse] = useState([]);
    const { lessonId } = useParams();
    const [newSeatsValue,setNewSeatsValue] = useState()

    const [lessonToEdit, setLessonToEdit] = useState(undefined);
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        getLesson()
    }, [lessonId])

    useEffect(() => {
        notification.config({
            placement: 'bottomRight'
        });
    }, []);



    const getLesson = async () => {
        try {
            const lesson = await axios.get(process.env.REACT_APP_URI + `/v1/lessons/${lessonId}`)
            setLessonToEdit(lesson.data)
        } catch (error) {
            console.log("error fetching", error)
        }
    }

    function range(start, end) {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    }

    const handleDateChange = (dateFromEvent) => {
        const dateToSave = dateFromEvent.$d
        dateToSave.setHours(12, 0, 0, 0)
        setDate(dateToSave);
    }

    const handleHourChange = (hourFromEvent) => {
        if (hourFromEvent && hourFromEvent.length === 2) {
            const startHour = new Date(hourFromEvent[0]).getHours();
            const startMinutes = new Date(hourFromEvent[0]).getMinutes();
            const finishHour = new Date(hourFromEvent[1]).getHours();
            const finishMinutes = new Date(hourFromEvent[1]).getMinutes();
            const startTime = `${startHour < 10 ? '0' : ''}${startHour}:${startMinutes < 10 ? '0' : ''}${startMinutes}:00`;
            const endTime = `${finishHour < 10 ? '0' : ''}${finishHour}:${finishMinutes < 10 ? '0' : ''}${finishMinutes}:00`;
            setStartHour(startTime)
            setFinishHour(endTime)
        }
    };

    function disabledDateTime() {
        if (!!disableHours && disableHours.length > 0) {
            const [startHour, endHour] = disableHours[0];

            return {
                disabledHours: () => range(0, startHour),
                disabledMinutes: (selectedHour) => {
                    if (selectedHour === startHour) {

                        return range(0, new Date().getMinutes());
                    }
                    return [];
                },
            };
        }
        return {};
    }

    const disabledReason = () => {
        const isDateChanged = !!date;
        const isHourChanged = !!startHour && !!finishHour;
        const isSeatsChanged = newSeatsValue >= 0;
    
        // Devuelve verdadero si ninguno de los campos requeridos se ha modificado o newSeatsValue es negativo
        return !(isDateChanged || isHourChanged || isSeatsChanged);
    };

    const handleEdit = async (e) => {
        e.preventDefault();
        const updateLesson = {
            lessonDate: date && date,
            startHour: startHour && startHour,
            finishHour: finishHour && finishHour,
            seats:+newSeatsValue
        };

        await axios.patch(process.env.REACT_APP_URI + `/v1/lessons/${lessonId}`, updateLesson)
            .then(() => {
                openNotification(
                    { type: "success", content: "Taller editado" },
                    "El taller se ha editado satisfactoriamente.",
                    "bottomRight"
                );
                setTimeout(() => window.history.back(), 1700);
            });
    };



    const openNotification = (message, description, placement) => {
        api[message.type]({
            message: message.content,
            description,
            placement,
        });
    };



    return (
        <>
            {contextHolder}
            <Navbar />
            <div className="col-12 col-lg-5 col-md-6 p-3 p-md-5">
                <div className="cursor-pointer d-flex align-items-center gap-2 mb-4">
                    <LeftOutlined />
                    <a onClick={() => window.history.back()}>Volver</a>
                </div>
                <div className="card border">
                    <h6 className="border-bottom p-lg-3">Edite horarios y plazas del taller seleccionado</h6>
                    <div className="card-body">
                        <div style={{ fontSize: '14px', marginBottom: '2rem' }}>
                            <div>
                                Fecha actual : {lessonToEdit && dayjs(lessonToEdit.lessonDate).format('DD/MM/YYYY')}
                            </div>
                            <div>
                                Horario actual : {lessonToEdit && lessonToEdit.startHour + ' hs'} a {lessonToEdit && lessonToEdit.finishHour + ' hs'}
                            </div>
                        </div>
                        <div className="form-group d-grid">
                            <label>Fecha</label>
                            <DatePicker
                                locale={es_ES}
                                firstDayOfWeek={1}
                                placeholder="Seleccionar fecha"
                                onChange={handleDateChange}
                                disabledDate={(current) =>
                                    current && current < dayjs().startOf("day")
                                }
                            />
                        </div>
                        <div className="form-group d-grid">
                            <label>Hora</label>
                            <TimePicker.RangePicker
                                format="HH:mm"
                                hideSecond
                                disabledTime={disabledDateTime}
                                placeholder={["Desde", "Hasta"]}
                                onChange={handleHourChange}
                            />
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Plazas</label>
                                <Input
                                    // onKeyPress={handleKeyPress}
                                    min={0}
                                    type="number"
                                    className="form-control"
                                    name="phone"
                                    value={newSeatsValue}
                                    onChange={(e)=>setNewSeatsValue(e.target.value)}
                                />
                            </div>
                        </div>
                        <div style={{ fontSize: '14px', marginBottom: '2rem' }}>
                            <div>
                                Nueva Fecha : {date ? dayjs(date).format('DD/MM/YYYY') : 'Sin modificación'}
                            </div>
                            <div>
                                Nuevo Horario : {(startHour && finishHour) ? startHour + ' hs' + ' a ' + finishHour + ' hs' : 'Sin modificación'}
                            </div>
                            <div>
                                Plazas : {(newSeatsValue && lessonToEdit) ? ' De ' + lessonToEdit?.seats + ' plazas actuales  a ' + newSeatsValue + ' plazas ' : 'Sin modificación'}
                            </div>
                        </div>
                    </div>
                    <div className="justify-content-right">
                        <button
                            onClick={(e) => handleEdit(e)}
                            disabled={disabledReason()}
                            className="btn btn-primary w-200-px float-end me-3"
                            type="submit"
                        >
                            Confirmar cambios
                        </button>
                    </div>
                  
                </div>
            </div>
        </>
    );
};
export default EditLesson;
