import { Button, DatePicker, Form, Input, Select, notification } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import axios from "axios";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import usePostRequest from "../../hooks/data/use-post-request";
import { Option } from "antd/es/mentions";
import { optionsForPasta, optionsForSelect, optionsForSelectMexican } from "../../utils/constants";
import es_ES from 'antd/es/date-picker/locale/es_ES';
import 'dayjs/locale/es';


const PersonalData = ({
  otherRequest,
  handleOtherRequestChange,
  voucherId,
  personalData,
  classSelected,
  goToPrevious,
  hideButton,
  voucherCreated,
  handlePersonalDataChange,
  handleSpecialRequestsChange,
  isStepper,
  handleBirthdateSelection,
  vouchers,
  atrapaloQuantity
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isBookingPage = location.pathname === "/reserva";
  const isBackOfficePage = location.pathname === "/backoffice/agregar-reserva";
  const [api, contextHolder] = notification.useNotification();
  const voucher = vouchers?.find((v) => v.id === voucherId);


  const lessonsURL =
    process.env.REACT_APP_URI +
    `/v1/lessons/${classSelected?.id}/voucher/${voucherId}/client`;
  const { handleClick, isLoading, error } = usePostRequest(lessonsURL);



  dayjs.locale('es');



  const openNotification = (placement) => {
    api.success({
      message: `Reserva creada`,
      description: "Su reserva ha sido creada correctamente.",
      placement,
    });
  };
  const onSavePersonalData = () => {
    personalData.specialRequests.push(otherRequest)
    const postData = {
      lessonId: classSelected?.id,
      voucherId: voucherCreated ? voucherCreated.id : voucherId,
      name: personalData?.name,
      email: personalData?.email,
      phone: `+34${personalData?.phone}`,
      birthdate: personalData?.birthDate,
      specialRequests: personalData?.specialRequests,
      isAtrapaloBooking: voucherCreated && voucherCreated.source === 'atrapalo' ? true : false
    };
    const pdfData = {
      quantity: voucher ? voucher?.seatsQty : atrapaloQuantity,
      message: classSelected.course.confirmationText,
      title: classSelected?.course.title,
      lessonDate: classSelected?.lessonDate,
      startHour: classSelected?.startHour,
      finishHour: classSelected?.finishHour,
      name: personalData?.name,
      email: personalData?.email,
      phone: personalData?.phone,
    };

    axios
      .post(
        process.env.REACT_APP_URI +
        `/v1/lessons/${classSelected?.id}/voucher/${voucherCreated ? voucherCreated.id : voucherId}/client`,
        postData
      )
      .then((response) => {
        if (response.status === 201) {
          openNotification("bottomRight");
          setTimeout(
            () => isBackOfficePage ? navigate(-1) : navigate("/reserva/exito", { state: { data: pdfData } }),
            1700
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 404 || error.response.status === 409) {
            api.error({
              message: `Algo ha salido mal`,
              description: "Por favor introduzca los datos nuevamente.",
              placement: "bottomRight",
            });
          } else {
            api.error({
              message: `Ha ocurrido un error en el servidor.`,
              description: `Por favor inténtelo nuevamente o <a href="tel:34673553301">contactanos al 34673553301.</a>.`,
              placement: "bottomRight",
            });
            setTimeout(() => navigate("/reserva/error"), 400);
          }
        } else {
          api.error({
            message: `Ha ocurrido un error en el servidor.`,
            description: `Por favor inténtelo nuevamente o <a href="tel:34673553301">contactanos al 34673553301.</a>.`,
            placement: "bottomRight",
          });
          setTimeout(() => navigate("/reserva/error"), 400);
        }
      });
  };


  const handleKeyPress = e => {
    const charCode = e.which || e.keyCode;

    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
    }
  };

  const disabledReason = () => {
    return (!personalData.name || !personalData.email || !personalData.phone || !personalData.birthDate)
  }



  return (
    <div
      className={
        isBookingPage &&
        "mx-auto card shadow-xs border bg-gray-100 col-lg-4 col-sm-12"
      }
    >
      {contextHolder}
      <Form className="card-body p-lg-4">
        {!!classSelected || isBackOfficePage ? (
          <>
            {isBookingPage && (
              <>
                <div className="d-flex align-items-center gap-2 mb-4">
                  <LeftOutlined />
                  <a onClick={() => goToPrevious()}>Seleccionar fecha</a>
                </div>
                <h3>Tus datos</h3>
              </>
            )}
            <div class="form-group">
              <label>Nombre del alumno</label>
              <Form.Item
                name="name"
                onChange={(e) => handlePersonalDataChange(e)}
                rules={[
                  { required: true, message: "Por favor, ingresa tu nombre." },
                ]}
              >
                <Input
                  name="name"
                  onChange={(e) => handlePersonalDataChange(e)}
                  placeholder="Juan Pérez"
                />
              </Form.Item>
            </div>
            <div class="form-group">
              <label>Email del alumno</label>
              <Form.Item
                name="email"
                onChange={(e) => handlePersonalDataChange(e)}
                rules={[
                  { required: true, message: "Por favor, ingresa tu email." },
                  {
                    pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message:
                      "El formato correcto de un email es juanperez@mail.com.",
                  },
                ]}
              >
                <Input
                  name="email"
                  onChange={(e) => handlePersonalDataChange(e)}
                  placeholder="juanperez@mail.com"
                />
              </Form.Item>
            </div>
            <div class="form-group">
              <label>Teléfono del alumno</label>
              <Form.Item
                name="phone"
                onChange={(e) => handlePersonalDataChange(e)}
                rules={[
                  {
                    required: true,
                    message: "Por favor, ingresa tu teléfono.",
                  },
                  {
                    min: 6,
                    message: "El teléfono debe tener más de 6 carácteres.",
                  },
                ]}
              >
                <Input
                  onKeyPress={handleKeyPress}
                  name="phone"
                  onChange={(e) => handlePersonalDataChange(e)}
                  placeholder="123456789"
                />
              </Form.Item>
            </div>
            <div class="form-group">
              <label>Fecha de nacimiento</label>
              <Form.Item
                name="birthDate"
                onChange={(date, dateString) =>
                  handleBirthdateSelection(dateString)
                }
                rules={[
                  {
                    required: true,
                    message: "Por favor, ingresa tu fecha de nacimiento.",
                  },
                ]}
              >
                <DatePicker
                  locale={es_ES}
                  firstDayOfWeek={1}
                  placeholder="Seleccionar"
                  size="middle"
                  className="w-100"
                  name="birthDate"
                  onChange={(date, dateString) =>
                    handleBirthdateSelection(dateString)
                  }
                  disabledDate={(current) =>
                    current && current > dayjs().startOf("day")
                  }
                />
              </Form.Item>
              {
                (classSelected?.course.title === 'Taller de sushi') &&
                <>
                  <div className='d-flex flex-column pb-3 gap-1'>
                    <label>Selecciona pedidos especiales (opcional)</label>
                    <Select
                      mode="multiple"
                      placeholder="Selecciona o agrega pedidos especiales (opcional)"
                      value={personalData.specialRequests}
                      style={{ flex: 1 }}
                      onChange={handleSpecialRequestsChange}
                    >
                      {classSelected?.course?.title.toLowerCase().includes('mexicano') ? (
                        optionsForSelectMexican.map((el) => (
                          <Option value={el.value} key={el.value}>
                            {el.label}
                          </Option>
                        ))
                      ) : classSelected?.course?.title.toLowerCase().includes('sushi') ? (
                        optionsForSelect.map((el) => (
                          <Option value={el.value} key={el.value}>
                            {el.label}
                          </Option>
                        ))
                      ) : (
                        optionsForPasta.map((el) => (
                          <Option value={el.value} key={el.value}>
                            {el.label}
                          </Option>
                        ))
                      )}
                    </Select>
                  </div>
                  <div className='d-flex gap-3 '>
                    <Input
                      name="other"
                      className='mb-3'
                      placeholder="Otro (Opcional)"
                      value={otherRequest}
                      onChange={(e) => handleOtherRequestChange(e.target.value)}
                    />
                  </div>
                </>
              }
            </div>
            {
              (!isBackOfficePage || isStepper) &&
              <button
                color=''
                disabled={disabledReason()}
                class="btn btn-primary mt-2 mb-0"
                onClick={() => onSavePersonalData()}
              >
                Confirmar
              </button>
            }


          </>
        ) : (
          <p className="p-lg-4">
            Antes de introducir tus datos, debes seleccionar una fecha para
            realizar el taller.
          </p>
        )}
      </Form>
    </div>
  );
};
export default PersonalData;
