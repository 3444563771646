import Navbar from "../components/navbar";
import Contact from '../components/store/contact'
import GroupCourseHero from "../components/groupCourse/hero";
import image1 from "../assets/images/hero/team_building_4.jpg";
import image2 from "../assets/images/hero/team_building_11.jpg";
import image3 from "../assets/images/hero/team_building_2.jpg";
import image4 from "../assets/images/hero/team_building_6.jpg";

export const GroupCourse = () => {
  return (
    <>
      <Navbar />
      <GroupCourseHero 
        title={"Talleres Privados"} 
        description={"Lo festejamos todo. Festeja tu cumpleaños, despedida de soltero y hasta tu divorcio. Aprenderás a preparar platos deliciosos y saludables de una manera fácil y divertida. ¡Te garantizamos una experiencia inolvidable!"} />
      <div className="card p-lg-6">
        <div className="row mb-6">
          <div className="col-12 col-lg-5">
            <img class="w-90 rounded-2" src={image1} alt={image1} />
          </div>
          <div className="col-12 col-lg-7">
            <img class="w-90 rounded-2" src={image2} alt={image2} />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-7">
            <img class="w-90 rounded-2" src={image3} alt={image3} />
          </div>
          <div className="col-12 col-lg-5">
            <img class="w-90 rounded-2" src={image4} alt={image4} />
          </div>
        </div>
      </div>
    </>
  );
};
export default GroupCourse;
