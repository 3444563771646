import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Confetti from "react-confetti-explosion";
import purchaseSuccess from "../../assets/images/hero/success_purchase.svg";
import Navbar from "../navbar";
import { DataContext } from "../../context/DataContext";
import useGetPaymentDetails from "../../hooks/data/use-get-payment";

const PurchaseSuccess = () => {
  const location = useLocation();
  const { updateData } = useContext(DataContext);

  // Extraer paymentId de la URL
  const queryParams = new URLSearchParams(location.search);
  const paymentId = queryParams.get('paymentId');

  // Obtener los detalles del pago usando el hook personalizado
  const { paymentDetails, loading, error } = useGetPaymentDetails(paymentId);

  useEffect(() => {
    if (paymentDetails) {
      // Actualizar DataContext con los detalles del pago
      updateData(paymentDetails);
    }
  }, [paymentDetails, updateData]);

  // Aquí puedes implementar lógica adicional para mostrar los datos o manejar el estado de carga y error

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paymentId = queryParams.get('paymentId');

    if (paymentId) {
  
    }
  }, [location]);

  const styles = {
    background: {
      minHeight: "85vh",
    },
  };

  return (
    <>
      <Confetti
        width={3000}
        height={3000}
        numberOfPieces={2000}
        force={1}
        duration={8000}
      />
      <Navbar />
      <div className="page-header py-5 py-md-0" style={styles.background}>
        <div className="container text-center col-lg-6 col-sm-12">
          <div className="mx-auto">
            <img src={purchaseSuccess} width={200} />
          </div>
          <div className="col-lg-8 mx-auto">
            <h2 className="mb-2">Compra exitosa</h2>
            <p>
              Revise su correo electronico. Le hemos enviado los detalles de su
              compra.
            </p>
            <div className="card border p-lg-3">
              <p>Nombre: {paymentDetails?.buyer?.name || 'Nombre no disponible'}</p>
              <p>Correo electrónico: {paymentDetails?.buyer?.email || 'Correo no disponible'}</p>
              <p>Cantidad: {paymentDetails?.quantity || 'Cantidad no disponible'} persona/s</p>
              <p className="m-0">Precio total: € {paymentDetails?.totalPrice || 'Precio no disponible'}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PurchaseSuccess;
