// DataProvider.js
import React, { useContext, useState } from "react";
import {DataContext} from "./DataContext";

const DataProvider = ({ children }) => {

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedLesson,setSelectedLesson] = useState();
  const [courseSelected,setCourseSelected] = useState();

  const [data, setData] = useState({
    name: "",
    email: "",
    quantity: "",
    totalPrice: "",
    lessonId:''
  });

  const updateData = (newData) => {
    setData(newData);
  };

  return (
    <DataContext.Provider value={{ data, updateData,selectedDate,setSelectedDate,selectedLesson,setSelectedLesson, setCourseSelected, courseSelected }}>
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;

export const useCourse = () => useContext(DataContext);
export const useDate = () => useContext(DataContext);
export const useLesson = () => useContext(DataContext);