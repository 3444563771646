import { useState } from 'react';

const usePostRequest = (url) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleClick = async (postData) => {

    setIsLoading(true);
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      });

      if (!response.ok) {
        throw new Error('Request failed');
      }

      setIsLoading(false);
      return response.json(); // Retornar los datos de respuesta
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };

  return { handleClick, isLoading, error };
};

export default usePostRequest;
