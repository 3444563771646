import { useState } from "react";
import { Collapse } from "antd";
import { Link, useNavigate } from "react-router-dom";
import ProductGallery from "./productGallery";
import ProductHero from "./hero";
import useIsMobile from "../../hooks/responsive/use-is-mobile.js";
import ProductTimeline from "./productTimeline";
import { useCourse } from "../../context/Provider.js";


const timeline = [
  {
    title: "Presentación",
    description: "Breve presentacion de los participantes y profesor.",
  },
  {
    title: "Historia",
    description:
      "Pequeña teoria sobre la historia y los origenes de la gastronomia del taller.",
  },
  {
    title: "Ingredientes",
    description:
      "Descripción de los ingredientes y utensilios que se utlizaran durante la experiencia.",
  },
  {
    title: "Práctica",
    description:
      "Elaboración de los distintos platos que configuran el taller.",
  },
  {
    title: "Concurso redes sociales",
    description:
      "La mejor fotografia publicada en redes sociales entrara en un concurso, sera premiada con un taller grtatis para el ganador y un acompañante! *Concurso cada 3 meses.",
  },
  {
    title: "Despedida",
    description:
      "Se da por terminado el taller despues de un feedback e intercanvio de opiniones.",
  },
  {
    title: "Entrega de material",
    description:
      "Entrega de archivos PDF (resumen del curso y recetas) via whatsapp.",
  },
];

export const ProductOverview = ({ course }) => {
  const { courseSelected, setCourseSelected } = useCourse();

  const { Panel } = Collapse;
  const isMobile = useIsMobile();
  const courseTitle = course?.title?.split(' ').join('-').toLowerCase();
  const [classSelected, setClassSelected] = useState();
  const navigate = useNavigate();

  const handleOnClickBuy =() =>{
    setCourseSelected(course)
    navigate(`/${courseTitle}/compra`)
  }

  return (
    <>
      <ProductHero 
        image={course?.heroImage} 
        title={course?.title} courseId={course?.id}
 />
      <div id="producto" className="card card-product card-plain p-lg-6">
        <div className="row mt-5">
          <div className="col-12 col-lg-8 border-end">
            {course?.title?.length !== 0 && (
              <div className="d-flex gap-4">
                <h2>{course?.title}</h2>
                <h3 className="font-weight-normal">
                  {isMobile && "€" + course?.price}
                </h3>
              </div>
            )}
            {course?.description?.length !== 0 && <p>{course?.description}</p>}
            {/* <Collapse className="my-4 w-60">
              <Panel
                header={
                  !!classSelected
                    ? moment(classSelected?.lessonDate).format("YYYY-MM-DD") +
                      classSelected?.lessonTime
                    : `Seleccionar fecha`
                }
                key="1"
              >
                <DateSelection
                  course={course}
                  setClassSelected={(classSelected) =>
                    setClassSelected(classSelected)
                  }
                />
              </Panel>
            </Collapse> */}
            {course?.highlights?.length !== 0 && (
              <>
                <h6>Información adicional</h6>
                <ul className="text-sm">
                  {course?.highlights?.map((highlight) => (
                    <li className="mb-2">{highlight}</li>
                  ))}
                </ul>
              </>
            )}
            {course?.items_included?.length !== 0 && (
              <>
                <h6>Qué incluye</h6>
                <ul className="text-sm">
                  {course?.items_included?.map((highlight) => (
                    <li className="mb-2">{highlight}</li>
                  ))}
                </ul>
              </>
            )}
          </div>
          <div className="col-12 col-lg-4 ps-4">
            <form action="" method="post">
              {!isMobile && course?.price?.length !== 0 && (
                <div className="d-flex mb-2">
                  <h3 className="font-weight-normal">
                    {course?.price?.toFixed(2)} €
                  </h3>
                </div>
              )}
              <hr class="dark horizontal my-4" />
              {course?.discounts?.length !== 0 && (
                <div className="mb-2">
                  <h5 className="mb-4">Promociones</h5>
                  {course?.discounts?.map((discount) => (
                    <p className="mb-2">
                      De {discount.quantity} personas - {discount.amount}% de
                      descuento
                    </p>
                  ))}
                </div>
              )}
              <div className="my-5">
                {/* <a className="" href={'https://wa.me/+34673553301?text=Quiero%20saber%20mas%20sobre%20sus%20talleres'}> Reservar una plaza</a> */}
                {/* <a className="btn btn-primary btn-lg w-100" href={'https://wa.me/+34673553301?text=Quiero%20saber%20mas%20sobre%20sus%20talleres'}> Comprar</a> */}
                
                <div
                  onClick={handleOnClickBuy}
                  className="btn btn-primary btn-lg w-100 cursor-pointer"
                >
                  Comprar
                </div>
              </div>
            </form>
          </div>
        </div>
        {course?.images?.length !== 0 && (
          <ProductGallery 
          courseId={course.id}
          images={course?.images} />
        )}
      </div>
      <hr class="dark horizontal my-4" />
      <ProductTimeline timeline={timeline} />
    </>
  );
};
export default ProductOverview;
