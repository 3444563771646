import { useEffect, useState } from "react";
import {
  DatePicker,
  InputNumber,
  notification,
  Select,
  TimePicker,
} from "antd";
import { LeftOutlined } from "@ant-design/icons";
import axios from "axios";
import dayjs from "dayjs";
import Navbar from "../../../navbar";
import useGetCourses from "../../../../hooks/data/use-get-courses";
import useGetLessons from "../../../../hooks/data/use-get-lessons";
import es_ES from 'antd/es/date-picker/locale/es_ES';
import 'dayjs/locale/es';


const AddLesson = () => {
  const [date, setDate] = useState();
  const [dates, setDates] = useState([]);
  const [dayOfTheWeek, setDayOfTheWeek] = useState();
  const [time, setTime] = useState();
  const [repeat, setRepeat] = useState();
  const [course, setCourse] = useState();
  const [seats, setSeats] = useState();
  const [repeatArray, setRepeatArray] = useState([]);
  const [repeatEveryWeekDates, setRepeatEveryWeekDates] = useState([]);
  const [repeatEveryDayDates, setRepeatEveryDayDates] = useState([]);
  const [repeatEveryTwoWeeksDates, setRepeatEveryTwoWeeksDates] = useState([]);
  const [repeatEveryFourWeeksDates, setRepeatEveryFourWeeksDates] = useState(
    []
  );
  const [notificationSuccess, setNotificationSuccess] = useState(false);
  const courses = useGetCourses();
  const lessons = useGetLessons();
  const [api, contextHolder] = notification.useNotification();

  dayjs.locale('es');

  const daysOfWeek = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];

  const coursesArray = courses?.map((course) => {
    return {
      value: course.id,
      label: course.title,
    };
  });
  const lessonsArray = lessons?.map((lesson) => {
    return {
      ...lesson,
      lessonDate: new Date(lesson.lessonDate),
    };
  });

  const targetDate = date && new Date(date);
  const [disableHours, setDisableHourse] = useState([]);

  useEffect(() => {
    const array = lessonsArray?.filter(
      (lesson) =>
        course === lesson.courseId &&
        lesson.lessonDate.getTime() === targetDate?.getTime()
    );
    setDisableHourse(
      array?.map((lesson) => {
        return [lesson.startHour.slice(0, 2), lesson.finishHour.slice(0, 2)];
      })
    );
  }, [course, date]);

  useEffect(() => {
    const dayName =
      dayOfTheWeek?.[0] === 0
        ? `${dayOfTheWeek?.[1]}s`
        : dayOfTheWeek?.[0] === 6
          ? `${dayOfTheWeek?.[1]}s`
          : dayOfTheWeek?.[1];

    setRepeatArray([
      { value: "notRepeat", label: `No se repite` },
      { value: "everyDay", label: `Todos los días del mes de ${getMonthName(date?.getMonth())}` },
      { value: "everyWeek", label: `Cada semana, los ${dayName} del mes de ${getMonthName(date?.getMonth())}` },
      {
        value: "everyTwoWeeks",
        label: `Cada dos semanas, los ${dayName} del mes de ${getMonthName(date?.getMonth())} `,
      },
      {
        value: "everyFourWeeks",
        label: `Cada cuatro semanas, los ${dayName} ${getMonthName(date?.getMonth())} `,
      },
    ]);
  }, [dayOfTheWeek, date]);

  function getMonthName(monthIndex) {
    const months = [
      "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
      "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];
    return months[monthIndex];
  }

  const handleDateChange = (dateFromEvent, dateString) => {
    const dateSelected = new Date(dateFromEvent.$d).getDay();
    const dateToSave = dateFromEvent.$d
    dateToSave.setHours(12, 0, 0, 0)

    setDate(dateToSave);
    setDayOfTheWeek([dateSelected, daysOfWeek[dateSelected]]);
  };

  const handleTimeChange = (time, timeString) => {
    setTime(timeString);
  };

  useEffect(() => {
    const everyWeekArray = [];
    const everyDayArray = [];
    const everyTwoWeeksArray = [];
    const everyFourWeeksArray = [];
    const oneYearLaterDate = new Date(date);
    oneYearLaterDate.setFullYear(oneYearLaterDate.getFullYear() + 1);
    for (
      let currentDate = new Date(date);
      currentDate <= oneYearLaterDate;
      currentDate.setDate(currentDate.getDate() + 1)
    ) {
      everyDayArray.push(new Date(currentDate));
      setRepeatEveryDayDates(everyDayArray);
      if (currentDate.getDay() === new Date(date).getDay()) {
        everyWeekArray.push(new Date(currentDate));
        setRepeatEveryWeekDates(everyWeekArray);
      }
    }
    for (
      let currentDate = new Date(date);
      currentDate <= oneYearLaterDate;
      currentDate.setDate(currentDate.getDate() + 14)
    ) {
      if (currentDate.getDay() === new Date(date).getDay()) {
        everyTwoWeeksArray.push(new Date(currentDate));
        setRepeatEveryTwoWeeksDates(everyTwoWeeksArray);
      }
    }
    for (
      let currentDate = new Date(date);
      currentDate <= oneYearLaterDate;
      currentDate.setDate(currentDate.getDate() + 28)
    ) {
      if (currentDate.getDay() === new Date(date).getDay()) {
        everyFourWeeksArray.push(new Date(currentDate));
        setRepeatEveryFourWeeksDates(everyFourWeeksArray);
      }
    }
  }, [date, repeat]);

  useEffect(() => {
    if (repeat === "everyWeek") {
      setDates(repeatEveryWeekDates);
    } else if (repeat === "everyDay") {
      setDates(repeatEveryDayDates);
    } else if (repeat === "everyTwoWeeks") {
      setDates(repeatEveryTwoWeeksDates);
    } else if (repeat === "everyFourWeeks") {
      setDates(repeatEveryFourWeeksDates);
    } else if (repeat === "notRepeat") {
      setDates([date]);
    }
  }, [date, repeat, repeatEveryWeekDates, repeatEveryDayDates]);

  const handleRepeatChange = (value) => {
    setRepeat(value);
  };
  const handleCourseChange = (value) => {
    setCourse(value);
  };
  const handleSeatsChange = (value) => {
    setSeats(value);
  };

  async function handleSubmit() {

    let filteredDates;

    if (dates.length > 0) {
      const selectedMonth = dayjs(date).startOf('month');
      const firstDayOfMonth = selectedMonth.startOf('day');
      const lastDayOfMonth = selectedMonth.endOf('month');

      filteredDates = dates.filter(date => (
        dayjs(date).isSame(firstDayOfMonth, 'day') || dayjs(date).isAfter(firstDayOfMonth, 'day')) &&
        (dayjs(date).isSame(lastDayOfMonth, 'day') || dayjs(date).isBefore(lastDayOfMonth, 'day')));

    }


    try {
      if (filteredDates?.length === 1 || dates.length === 1) {
        const postData = {
          lessonDate: dates || filteredDates,
          dayOfTheWeek,
          startHour: time?.[0],
          finishHour: time?.[1],
          courseId: course,
          seats,
        };
        await axios.post(process.env.REACT_APP_URI + `/v1/lessons`, postData);
        setNotificationSuccess(true);
      } else {
        for (const item of filteredDates) {
          const postData = {
            lessonDate: item,
            dayOfTheWeek,
            startHour: time?.[0],
            finishHour: time?.[1],
            courseId: course,
            seats,
          };
          axios.post(process.env.REACT_APP_URI + `/v1/lessons`, postData);
        }
        setNotificationSuccess(true);
      }

      setTimeout(() => {
        window.location.href = '/backoffice'
      }, 3000);
    } catch (error) {
      console.log("Tuve un error creando", error);
    }
  }




  useEffect(() => {
    if (notificationSuccess === true) {
      api.success({
        message: `Taller creado`,
        description: "El taller ha sido creado con éxito.",
        placement: "bottomRight",
      });
    }
  }, [notificationSuccess]);

  function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  function disabledDateTime() {
    if (!!disableHours) {
      return {
        disabledHours: () =>
          range(
            parseFloat(disableHours?.[0]?.[0]),
            parseFloat(disableHours?.[0]?.[1])
          ).splice(0, 20),
      };
    }
  }

  const disabledReason = () => {
    return (!course || !time || !date || !repeat || !seats)
  }



  return (
    <>
      {contextHolder}
      <Navbar />
      <div className="col-12 col-lg-5 col-md-6 p-3 p-md-5">
        <div className="cursor-pointer d-flex align-items-center gap-2 mb-4">
          <LeftOutlined />
          <a onClick={() => window.history.back()}>Volver</a>
        </div>
        <div className="card border">
          <h6 className="border-bottom p-lg-3">Agregar taller</h6>
          <div className="card-body">
            <div className="form-group d-grid">
              <label>Taller</label>
              <Select
                placeholder="Seleccionar taller"
                name="course"
                onChange={handleCourseChange}
                options={coursesArray}
              />
            </div>
            <div className="form-group d-grid">
              <label>Fecha</label>
              <DatePicker
                locale={es_ES}
                firstDayOfWeek={1}
                placeholder="Seleccionar fecha"
                onChange={handleDateChange}
                disabledDate={(current) =>
                  current && current < dayjs().startOf("day")
                }
              />
            </div>
            <div className="form-group d-grid">
              <label>Hora</label>
              <TimePicker.RangePicker
                format="HH:mm"
                hideSecond
                disabledTime={disabledDateTime}
                placeholder={["Desde", "Hasta"]}
                onChange={handleTimeChange}
              />
            </div>
            <div className="form-group d-grid">
              <label>Repetir</label>
              <Select
                name="repeat"
                value={repeat}
                onChange={handleRepeatChange}
                options={!!date ? repeatArray : []}
              />
            </div>
            <div className="form-group d-grid">
              <label>Cantidad de personas</label>
              <InputNumber
                placeholder="Seleccionar"
                min={1}
                onChange={handleSeatsChange}
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="justify-content-right">
            <button
              onClick={handleSubmit}
              disabled={disabledReason()}
              className="btn btn-primary w-200-px float-end me-3"
              type="submit"
            >
              Guardar taller
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddLesson;
