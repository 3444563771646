import BookingHero from "../components/booking/hero";
import StepsCarousel from "../components/booking/stepsCarousel";
import Navbar from "../components/navbar";
import Contact from "../components/store/contact";
const Booking = () => {
  return (
    <div className="position-relative">
      <Navbar />
      <BookingHero />
      <StepsCarousel />
    </div>
  );
};
export default Booking;
