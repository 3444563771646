import React from 'react'
import Navbar from '../components/navbar'

const CondicionesGenerales = () => {
    return (
        <>
            <Navbar />
            <div className="container mt-5 mb-5">
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'start', flexDirection: 'column' }}>
                    <strong><h1>Condiciones generales</h1></strong>
                    <strong><h3>Condiciones Generales - Experiencia Gastronómica con Muérdete</h3></strong>
                    <p> Estimado cliente,</p>
                        <p>
                        Gracias por elegir Muérdete para vivir una experiencia culinaria única. A continuación, te presentamos las condiciones generales que rigen tu participación en nuestros talleres. Te solicitamos que las leas detenidamente para garantizar una experiencia satisfactoria.
                        </p>
                    <strong><h5>Contacto :</h5></strong>
                    <ul>
                        <li><b>Dirección:</b> Carrer de Casanova 3, local Muérdete, 08011 Barcelona, Barcelona</li>
                    </ul>
                    <strong><h5>Experiencia Ofrecida por Muérdete:</h5></strong>
                    <ul>
                        <li><b>Organizador de Experiencias :</b> Muérdete</li>
                        <li>
                            <b>Disponibilidad :</b>
                            <ul>
                                <li>Disponible de jueves a domingo, excepto en Navidad, Semana Santa, agosto y festivos de Barcelona.</li>
                                <li>Horarios: jueves y viernes por la tarde; sábados y domingos por la mañana y por la tarde.</li>
                                <li>Fechas sujetas al calendario del espacio.</li>
                            </ul>
                        </li>
                    </ul>
                    <strong><h5>Condiciones de Cancelación:</h5></strong>
                    <ul>
                        <li>Para la modificación de la fecha seleccionada, es obligatorio avisar con un mínimo de 10 días naturales de antelación. En el caso de que tu grupo esté formado por más de 5 participantes, se necesitan 16 días de antelación para la modificación de la fecha, y siempre vía WhatsApp al número de reservas: 673 55 33 01.</li>
                    </ul>
                    <strong><h5>Información Adicional:</h5></strong>
                    <ul>
                        <li>Se entregará un PDF resumen del taller con los pasos a seguir al finalizar el taller, para poder realizar la experiencia en casa.</li>
                        <li>La puntualidad es esencial y obligatoria el día de la experiencia.</li>
                    </ul>
                    <strong><h5>Devoluciones :</h5></strong>
                    <ul>
                        <li>Una vez adquirido el bono de compra, NO SE ADMITEN DEVOLUCIONES NI REEMBOLSOS.</li>
                    </ul>
                    <strong><h5>Validez del Bono:</h5></strong>
                    <ul>
                        <li>Dispones de 6 meses para realizar la experiencia.</li>
                        <li>Después de este periodo, el bono quedará anulado.</li>
                    </ul>
                    <strong><h5>Presentación en el Día de la Experiencia:</h5></strong>
                    <ul>
                        <li>Mostrar el mensaje de confirmación recibido por correo electrónico a tu llegada al taller.</li>
                    </ul>
                    <p>
                        Agradecemos tu atención a estas condiciones y esperamos que disfrutes al máximo de tu experiencia con Muérdete. Para cualquier consulta adicional, no dudes en contactarnos.
                    </p>

                    <p>
                        En el tratamiento de tus datos personales, el Titular aplicará los siguientes principios que se ajustan a las exigencias del nuevo reglamento europeo de protección de datos:
                    </p>
                    <p>
                        Saludos cordiales,
                    </p>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <p>
                        Muérdete Talleres de Cocina
                    </p>
                    <p>
                        WhatsApp: 673 55 33 01
                    </p>
                </div>
            </div>
        </>
    )
}

export default CondicionesGenerales