

import { useEffect, useState } from 'react';
import axios from 'axios'

const useGetLessons = () => {
    const [lessons, setLessons] = useState()
    useEffect(() => {
        axios.get(process.env.REACT_APP_URI+'/v1/lessons').then((response) => {
            setLessons(response.data);
        });
    }, []);
    return lessons
};

export default useGetLessons;
