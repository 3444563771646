import bookingBackground from '../../assets/images/hero/home_talleres_grupales.jpg'
import useIsMobile from '../../hooks/responsive/use-is-mobile';

const GroupCourseHero = ({title, description}) => {
    const isMobile = useIsMobile()
    const styles = {
        bookingBackground: {
            backgroundImage: 'url(' + bookingBackground + ')',
            minHeight: '90vh'
        },
        buttonsRow: {
            display: isMobile ? 'block' : 'flex',
        },
        button: {
            width: isMobile && '100%'
        }
    };

    return (
        <div class="page-header py-5 py-md-0" style={styles.bookingBackground}>
            <span class="mask bg-gradient-dark opacity-6" />
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-sm-9 text-center mx-auto">
                        <h1 class="text-white mb-4">{title}</h1>
                        <p class="lead text-white mb-sm-6 mb-4">{description}</p>
                        <div style={styles.buttonsRow} className='gap-4 justify-content-center'>
                            <a onClick={() => window.location.href = 'https://api.whatsapp.com/send/?phone=%2B34673553301&text=Quiero+saber+mas+sobre+el+taller+grupal'} target="_blank" style={styles.button} href='#booking-form' class="btn btn-white btn-lg">PEDIR PRESUPUESTO</a>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}
export default GroupCourseHero
