import { useEffect, useState } from "react";
import {  useParams } from "react-router-dom";
import axios from "axios";
import { Input, Form, notification, Select } from "antd";
import Navbar from "../../../navbar";
import useGetClients from "../../../../hooks/data/use-get-clients";
import { Option } from "antd/es/mentions";
import { LeftOutlined } from "@ant-design/icons";
import useGetLessons from "../../../../hooks/data/use-get-lessons";
import { useGetVouchers } from "../../../../hooks/data/use-crud-vouchers";


const EditStudentForm = () => {
  const [api, contextHolder] = notification.useNotification();
  const clients = useGetClients();
  const lessons = useGetLessons()
  const { studentId } = useParams();
  const vouchers = useGetVouchers();
  const student = clients?.find((student) => student?.id === studentId);
  const [formData, setFormData] = useState(student || {});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [otherRequest, setOtherRequest] = useState("");
  const [lessonSelected, setLessonSelected] = useState();

  useEffect(() => {
    setFormData(student);
  }, [student]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));


  };

  const filteredLessons = lessons?.filter(lesson => {
    const clientVoucher = vouchers?.find(
      (voucher) => voucher.id === student?.voucherId
    );
  
    const lessonDate = new Date(lesson.lessonDate);
    const today = new Date();
    const isLessonAfterToday = lessonDate >= today;
  
    const availableSeats = lesson.seats - (clientVoucher?.seatsQty || 0) > 0;
  
    return isLessonAfterToday && availableSeats;
  }).sort((a, b) => {
    // Ordenar por fecha ascendente
    const dateA = new Date(a.lessonDate);
    const dateB = new Date(b.lessonDate);
    if (dateA > dateB) return 1;
    if (dateA < dateB) return -1;
  
    // Si las fechas son iguales, ordenar por hora de inicio ascendente
    const startHourA = parseInt(a.startHour.split(":")[0]);
    const startHourB = parseInt(b.startHour.split(":")[0]);
    if (startHourA > startHourB) return 1;
    if (startHourA < startHourB) return -1;
  
    // Si las horas de inicio son iguales, ordenar por minutos de inicio ascendente
    const startMinuteA = parseInt(a.startHour.split(":")[1]);
    const startMinuteB = parseInt(b.startHour.split(":")[1]);
    if (startMinuteA > startMinuteB) return 1;
    if (startMinuteA < startMinuteB) return -1;
  
    return 0;
  });

  const openNotification = (message, description, placement) => {
    api[message.type]({
      message: message.content,
      description,
      placement,
    });
  };

  const actualLesson = lessons?.filter(lesson => lesson.students.includes(student?.id))



  const handleEdit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    formData.specialRequests.push(otherRequest)
    if (lessonSelected) {
      formData.newLessonId = lessonSelected;
      formData.actualLessonId = actualLesson[0].id;
      formData.voucherId = student?.voucherId;

    }
    axios.patch(process.env.REACT_APP_URI + `/v1/clients/${formData.id}`, formData)
      .then(() => {
        openNotification(
          { type: "success", content: "Alumno Editado" },
          "El alumno se ha editado satisfactoriamente.",
          "bottomRight"
        );
        setTimeout(() => window.history.back(), 1700);
      });

  }

  const optionsForSelect = [
    { value: 'vegan', label: 'Vegano' },
    { value: 'vegetarian', label: 'Vegetariano' },
    { value: 'glutenFree', label: 'Sin gluten' },
    { value: 'noShellfish', label: 'Sin mariscos' },
    { value: 'lactoseIntolerant', label: 'Intolerancia a la lactosa' },
    { value: 'noNuts', label: 'Sin frutos secos' },
    { value: 'diabetes', label: 'Diabetes' },
    { value: 'hypertension', label: 'Hipertensión' },
    { value: 'heartDisease', label: 'Enfermedad cardíaca' },
  ];

  const handleOtherRequestChange = (value) => {
    setOtherRequest(value);
  };


  const handleSelectLesson = (lessonSelected) => {
    setLessonSelected(lessonSelected);
  };



  const handleSpecialRequestsChange = (selectedValues) => {
    setFormData({
      ...formData,
      specialRequests: selectedValues,
    });
  };

  const handleKeyPress = e => {
    const charCode = e.which || e.keyCode;

    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
    }
  };

   function formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
  
    return `${day}-${month}-${year}`;
  }



  return (
    <>
      <Navbar />
      {contextHolder}
      <Form onSubmit={handleEdit} className='mb-2'>
        <div>
          <div className="col-12 col-lg-8 p-3 p-md-5">
            <div className="cursor-pointer d-flex align-items-center gap-2 mb-4">
              <LeftOutlined />
              <a onClick={() => window.history.back()}>Volver</a>
            </div>
            <h4 className="mb-4">{student?.title}</h4>
            <div className="card border">
              <div className='d-flex justify-content-between align-items-center border-bottom'>
                <h6 className="p-lg-3">Datos del cliente</h6>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Nombre</label>
                      <Input
                        className="form-control"
                        name="name"
                        value={formData?.name || ""}
                        onChange={handleChange}
                        placeholder="Enter the title"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Telefono</label>
                      <Input
                        onKeyPress={handleKeyPress}
                        type="text"
                        className="form-control"
                        name="phone"
                        value={formData?.phone || ""}
                        onChange={handleChange}
                        placeholder="Ingresa el telefono"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Email</label>
                      <Input
                        type="email"
                        className="form-control"
                        name="email"
                        value={formData?.email || ""}
                        onChange={handleChange}
                        placeholder="Ingresa el email"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <Select
                        mode="multiple"
                        placeholder="Selecciona o agrega pedidos especiales (opcional)"
                        value={formData?.specialRequests}
                        style={{ width: '100%' }}
                        onChange={handleSpecialRequestsChange}
                      >
                        {optionsForSelect.map((el) => (
                          <Option value={el.value} key={el.value}>
                            {el.label}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Input
                        name="other"
                        placeholder="Otro (opcional)"
                        value={otherRequest}
                        onChange={(e) => handleOtherRequestChange(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                {
                  lessons &&
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Seleccione un taller si desea modificar el actual</label>
                        <Select
                          placeholder="Selecciona el nuevo taller a asignar"
                          value={lessonSelected || ''}
                          style={{ width: '100%' }}
                          onChange={handleSelectLesson}
                        >
                          {filteredLessons.map((el) => {
                            const isActual = el.students.includes(student?.id);
                            const optionClassName = isActual ? 'bg-success' : '';
                            return (
                              <Option className={optionClassName} value={el.id} key={el.id}>
                                {formatDate(new Date(el.lessonDate))} - {el.startHour} a {el.finishHour} - {el.course.title} 
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                    </div>
                  </div>
                }
              </div>

            </div>
            <div className="justify-content-right">
              <button
                onClick={handleEdit}
                className="w-200-px btn btn-primary float-end me-4 mt-2"
                type="submit"
              >
                Guardar datos
              </button>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default EditStudentForm;
