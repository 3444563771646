

import { useEffect, useState } from 'react';
import axios from 'axios'

const useGetClients = () => {
    const [clients, setClients] = useState()
    useEffect(() => {
        axios.get(process.env.REACT_APP_URI+'/v1/clients').then((response) => {
            setClients(response.data);
        });
    }, []);
    return clients
};

export default useGetClients;
