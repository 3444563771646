import useIsMobile from '../../hooks/responsive/use-is-mobile';
import { S3_BUCKET_NAME } from '../../utils/constants';

const GroupCourseHero = ({ title, image, courseId }) => {

    const imageUrl = `${S3_BUCKET_NAME}/${courseId}/heroImage/${image}`

    const isMobile = useIsMobile()
    const styles = {
        bookingBackground: {
            backgroundImage:`url(${imageUrl}) `,
            minHeight: '90vh',
            backgroundSize: 'cover',
            backgroundRepeat:'no-repeat'

        },
        buttonsRow: {
            display: isMobile ? 'block' : 'flex',
        },
        button: {
            width: isMobile && '100%'
        }
    };


    return (
        <div class="page-header py-5 py-md-0" style={styles.bookingBackground}>
            <span class="mask bg-gradient-dark opacity-6" />
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-sm-9 text-center mx-auto">
                        <h1 class="text-white mb-5">{title}</h1>
                        <div style={styles.buttonsRow} className='gap-4 justify-content-center'>
                            <a href="#producto" style={styles.button} class="btn btn-white btn-lg">Leer más</a>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}
export default GroupCourseHero
