import { S3_BUCKET_NAME } from "../../utils/constants";


export default function ProductGallery({
  images,
  courseId
}) {

  return (
    <>
      <div className="row">
        {images?.map(image =>
          <div className="col-3">
            <img className="w-100 rounded-2" src={`${S3_BUCKET_NAME}/${courseId}/${image}`} alt={'Logo'} />
          </div>
        )}
      </div>
    </>
  );
}