import useIsMobile from "../../hooks/responsive/use-is-mobile";
import "../../assets/animations/animations.css";
import { Link } from "react-router-dom";

export const InfoPictureForOurSpace = ({
    title,
    subtitle,
    paragraphOne,
    image3,
}) => {
    const isMobile = useIsMobile();

    return (
        <div id="sobre-nosotros" className="container">
            <div className="sobre-nosotros row justify-content-between mx-auto pb-8 pt-8">
                <div className="col-lg-5">
                    <div className="mb-4">
                        {title && (
                            <h1 className={`mb-1 ${isMobile ? "text-2xl" : "text-6xl"}`} style={{ textAlign: "left", color: "#000" }}>
                                {title}
                            </h1>
                        )}
                        {subtitle && (
                            <h4 className={`${isMobile ? "text-xl" : "text-3xl"}`} style={{ textAlign: "left", color: "#000" }}>
                                {subtitle}
                            </h4>
                        )}
                    </div>
                    {paragraphOne && (
                        <p className={`${isMobile ? "text-lg" : "text-xl"}`} style={{ textAlign: "left", color: "#000" }}>
                            {paragraphOne}
                        </p>

                    )}
                    <Link
                        className=
                        {
                            isMobile
                                ? `btn btn-white btn-lg btn-lg text-xl mt-4`
                                : `btn btn-lg bg-purple text-white text-xl`
                        }
                        to="/nuestro-espacio"
                    >
                        Conoce mas
                    </Link>
                </div>
                <div className="col-lg-6">
                    <img className="w-100 rounded-3" src={image3} alt={image3} />
                </div>
            </div>
            <div className="circle"></div>
        </div>
    );
};

export default InfoPictureForOurSpace;
