import { useEffect, useState } from 'react';
import axios from 'axios';

export const useGetVouchers = () => {
    const [vouchers, setVouchers] = useState([]); // Inicializa con un array vacío

    useEffect(() => {
        axios.get(process.env.REACT_APP_URI+'/v1/vouchers')
            .then((response) => {
                setVouchers(response.data);
            })
            .catch((error) => {
                console.error("Error al cargar los vouchers:", error);
                // Manejar el error adecuadamente, por ejemplo, establecer un estado de error
            });
    }, []);

    return vouchers;
};


export const useGetVoucherByTrackAndCode = () => {
    const [voucher, setVoucher] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchVoucher = async (tracker, code) => {
        setLoading(true);
        setError(null);
        try {
            const urlCall = `${process.env.REACT_APP_URI}/v1/vouchers/tracker/${tracker}/code/${code}`;
            const response = await axios.get(urlCall);
            setVoucher(response.data);
        } catch (error) {
            console.error("Error al cargar los vouchers:", error);
            setError(error);
            setVoucher(null);
        } finally {
            setLoading(false);
        }
    };

    return { voucher, loading, error, fetchVoucher };
};

