import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Image } from "antd";
import { S3_BUCKET_NAME } from "../../../../utils/constants";


const SimpleImage = ({ title, heroImage, handleHeroImage, courseId }) => {

    const styles = {
        input: {
            display: "none",
            position: "absolute",
            zIndex: "-1",
        },
    };
    const acceptedFiles = ['image/jpeg', 'image/png'];


    return (
        <div className="card border">
            <div className="d-flex justify-content-between border-bottom p-lg-3">
                <h6>{title}</h6>
                <div className="btn m-0">
                    <input
                        name="images"
                        style={styles.input}
                        type="file"
                        accept={acceptedFiles}
                        id="hero-upload"
                        onChange={(e) => handleHeroImage(e.target.files)}
                    />
                    {
                        !heroImage &&
                        <label className="cursor-pointer" htmlFor="hero-upload">
                            <PlusOutlined /> Agregar
                        </label>
                    }

                </div>
            </div>
            <div className="card-body">
                <div className="grid-template-columns-4 mb-2">
                    {
                        heroImage &&
                        <div className="image-item border-radius-md border p-lg-2">
                            {typeof heroImage === 'string' ? (
                                <Image
                                    alt={`Imagen`}
                                    style={{ height: '200px' }}
                                    src={`${S3_BUCKET_NAME}/${courseId}/heroImage/${heroImage}`}
                                    className="border-radius-md image"
                                />
                            ) : (
                                <Image
                                    alt={`Imagen`}
                                    style={{ height: '200px' }}
                                    src={URL.createObjectURL(heroImage)}
                                    className="border-radius-md image"
                                />
                            )}
                            <button
                                className="btn bg-transparent border mt-2 w-100"
                                onClick={() => handleHeroImage([])}
                            >
                                <DeleteOutlined /> Delete
                            </button>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default SimpleImage;
